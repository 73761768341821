import BaseService from "./BaseService";

export default class DashboardService extends BaseService {
    public static getDashbaordDetails(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'dashboard', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
    public static getDashboardUserMetric(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'dashboard/user-metric', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
    public static getDashbaordFinancialMetric(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'dashboard/financial-metric', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
    public static getDashboardComplianceRiskMetrics(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'dashboard/compliance-risk-metrics', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
    public static getDashboardBehaviorAndEngagement(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'dashboard/behavior-and-engagement', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
    public static getDashboardPerformanceSystemMetrics(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'dashboard/performance-system-metrics', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
    public static getDashboardCustomerSupportMetrics(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'dashboard/customer-support-metrics', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
}