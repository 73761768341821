import React, { useReducer, useEffect } from "react";


let reducer = (info: any, newInfo: any) => {
  if (newInfo === null) {
    localStorage.removeItem("batch-info");
    return initialState;
  }
  return { ...info, ...newInfo };
};

const initialState = {
  syncing: false,
  batchGuid: null,
};

const batchInfo = localStorage.getItem("batch-info");
const localState = JSON.parse(batchInfo || "null");

const SyncContext = React.createContext({info: initialState, setInfo: (props: any) => {}});

function SyncProvider(props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) {
  const [info, setInfo] = useReducer(reducer, localState || initialState);

  useEffect(() => {
    localStorage.setItem("batch-info", JSON.stringify(info));
  }, [info]);

  return (
    <SyncContext.Provider value={{ info, setInfo }}>
      {props.children}
    </SyncContext.Provider>
  );
}

export { SyncContext, SyncProvider };
