import React from 'react';
import './NavBar.css';
// (Optional) Import component styles. If you are using Less, import the `index.less` file. 
import 'rsuite/styles/index.less'; // or 'rsuite/dist/rsuite.min.css'
import 'rsuite/Sidenav/styles/index.css';
import 'rsuite/Nav/styles/index.css';
import logo from '../../Images/logo.png';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import BigButton from '../../Component/BigButton';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import Tooltip from '@mui/material/Tooltip';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';


interface NavBarLeftProps { }
interface NavBarLeftState {
    expanded: boolean;
    activeKey: string;
    activeUrl: string;
    isToggled: boolean;
}

class NavBarLeft extends React.Component<NavBarLeftProps, NavBarLeftState> {
    constructor(props_: NavBarLeftProps) {
        super(props_);

        this.state = {
            expanded: true,
            activeKey: "1",
            activeUrl: '',
            isToggled: true
        }

        window.addEventListener("popstate", () => {
            this.setState({
                activeUrl: window.location.pathname
            });
        });
    }



    public render() {
        return (
            <div className={`nav-container ` + (this.state.isToggled ? 'active' : '')}>
                <div className='menu-toggle' onClick={() => {
                    this.setState({
                        isToggled: !this.state.isToggled
                    });
                }}>{
                        this.state.isToggled ? <KeyboardArrowRightIcon fontSize='large' /> : <KeyboardArrowLeftIcon fontSize='large' />}</div>
                <div className='logo-container'>
                    <div><img src={logo} alt="Logo" className="app-logo" /></div>
                    <div className='app-title'>PassportPay</div>
                </div>
                <div className='menu-container'>
                    <Tooltip title="Dashboard"><Link to="/dashboard" className={this.isSelected('dashboard')} onClick={() => { this.setActiveUrl('dashboard') }}><div className='menu-item'><DashboardIcon /> <span>Dashboard</span></div></Link></Tooltip>
                    <Tooltip title="Transaction Logs"><Link to="/transactionlogs" className={this.isSelected('transactionlogs')} onClick={() => { this.setActiveUrl('transactionlogs') }}><div className='menu-item'><BackupTableIcon /> <span>Transaction Logs</span></div></Link></Tooltip>
                    <Tooltip title="User Actions"><Link to="/useractions" className={this.isSelected('useractions')} onClick={() => { this.setActiveUrl('useractions') }}><div className='menu-item'><WorkHistoryIcon /> <span>User Actions</span></div></Link></Tooltip>
                    <Tooltip title="Compliance"><Link to="/compliance" className={this.isSelected('compliance')} onClick={() => { this.setActiveUrl('useractions') }}><div className='menu-item'><AssuredWorkloadIcon/> <span>Compliance</span></div></Link></Tooltip>

                </div>
                {/* <div>
                    <div style={{
                            display: 'inline-block',
                            float: 'inline-end'
                    }}>
                        <Link to="#" onClick={() => {
                            this.setState({
                                activeUrl: ''
                            }, () => {
                                localStorage.clear();
                                window.location.href = '/';
                            })

                        }}><div className='menu-item log-out'><LogoutIcon /> <span>Sign Out</span></div></Link>
                    </div>

                </div> */}


                {/* <Toggle
                    onChange={(value) => {
                        console.log(value);
                        this.setState({
                            expanded: value
                        })
                    }}
                    checked={this.state.expanded}
                    checkedChildren="Expand"
                    unCheckedChildren="Collapse"
                />
                <hr />
                <Sidenav expanded={this.state.expanded} defaultOpenKeys={['3', '4']}>
                    <Sidenav.Body>
                        <Nav activeKey={this.state.activeKey} onSelect={(key) => {
                            this.setState({
                                activeKey: key
                            })
                        }}>
                            <Nav.Item eventKey="1" icon={<DashboardIcon />}>
                                Dashboard
                            </Nav.Item>
                            <Nav.Item eventKey="2" icon={<GroupIcon />}>
                                User Group
                            </Nav.Item>
                            <Nav.Menu placement="rightStart" eventKey="3" title="Advanced" icon={<MagicIcon />}>
                                <Nav.Item eventKey="3-1">Geo</Nav.Item>
                                <Nav.Item eventKey="3-2">Devices</Nav.Item>
                                <Nav.Item eventKey="3-3">Loyalty</Nav.Item>
                                <Nav.Item eventKey="3-4">Visit Depth</Nav.Item>
                            </Nav.Menu>
                            <Nav.Menu
                                placement="rightStart"
                                eventKey="4"
                                title="Settings"
                                icon={<GearCircleIcon />}
                            >
                                <Nav.Item eventKey="4-1">Applications</Nav.Item>
                                <Nav.Item eventKey="4-2">Channels</Nav.Item>
                                <Nav.Item eventKey="4-3">Versions</Nav.Item>
                                <Nav.Menu eventKey="4-5" title="Custom Action">
                                    <Nav.Item eventKey="4-5-1">Action Name</Nav.Item>
                                    <Nav.Item eventKey="4-5-2">Action Params</Nav.Item>
                                </Nav.Menu>
                            </Nav.Menu>
                        </Nav>
                    </Sidenav.Body>
                    <Sidenav.Toggle onToggle={expanded => this.setState({
                        expanded: expanded
                    })} />
                </Sidenav> */}
            </div>
        )
    }

    private setActiveUrl(url: string) {
        this.setState({
            activeUrl: url
        })
    }

    private isSelected(screenName: string) {
        var url_ = window.location.href.replace(window.location.origin, '').replace('#', '');

        console.log(url_);

        return url_.indexOf(`/${screenName}`) != -1 ? 'selected' : '';
    }
}

export default NavBarLeft;