import BaseService from "./BaseService";

export default class AddCardService extends BaseService {
    public static getUserDetails(token: string) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'profile', {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        })
    }

    public static AddNewCard(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'payment/add/card/v2', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        });
    }
}