import { createTheme } from "@mui/material/styles";
import { lime, purple } from '@mui/material/colors';

export const greenTheme = createTheme({
    palette: {
        primary: {
            main: '#000',//#67d01c
            contrastText: 'gray' //'#8d7e7e'
        },
        secondary: {
            main: '#5aac3d',//#67d01c
            contrastText: '#ffffff',
        
            // main: '#1976d2',
            // light: '#42a5f5',
            // dark: '#1565c0',
            // contrastText: '#fff',
        }
    },
});
