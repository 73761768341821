import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';


interface AlertDialogPopopProps {
    open: boolean;
    onClose?: (state: boolean, action: string) => void;
    alertTitle: string;
    alertBody: string;
}
interface AlertDialogPopopState { }

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AlertDialogPopop extends React.Component<AlertDialogPopopProps, AlertDialogPopopState> {
    constructor(props_: AlertDialogPopopProps) {
        super(props_);
    }

    public render() {
        return (
            <Dialog
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    this.handleClose('cancel')
                }}
                fullWidth={true}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{this.props.alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {this.props.alertBody}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.handleClose('cancel');
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        this.handleClose('ok');
                    }} color='secondary' variant="contained">Ok</Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleClose = (action: string) => {
        if (this.props.onClose) {
            this.props.onClose(false, action);
        }
    };
}

export default AlertDialogPopop;