import BaseService from "./BaseService";

export default class UserLogsService extends BaseService {
    public static getUserAction(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'payment/transaction/all/logs', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
}