import { ShowBlocker, ShowCustomBlocker } from "../Component/LoaderComponent";
import { ShowAlert } from "../Component/ShowAlert";

export default class BaseService {
    public static sendRequest(url_: string, requestInit_: RequestInit, requestName_?: string,
        hideLoader?: boolean, noError_?: boolean, showCustomLoader?: boolean, noAutoCloseBlocker?: boolean) {

        if (!hideLoader) {
            ShowBlocker(true);
        }

        if (hideLoader && showCustomLoader) {
            ShowCustomBlocker(true);
        }

        if (!requestInit_) {
            requestInit_ = {};
        }
        if (!requestInit_.headers) {
            requestInit_.headers = {};
        }

        let request = fetch(url_, requestInit_)
            .then(r => {
                let isErrorHandled = false;
                // Unauthorized, so exit
                if (r.status == 401) {
                    r.text().then(resp => {
                        isErrorHandled = true;

                        ShowAlert("", resp, "error")
                            .then(() => {
                                localStorage.clear();
                                // window.location.href = `/?token=${resp}`;
                                window.location.href = `/`;
                            });
                    })
                } else if (r.status == 403) {
                    isErrorHandled = true;
                    r.json().then(resp => {
                        let errorMsg = "You do not have permission to access. Contact your administrator.";
                        ShowAlert("Insufficient permission", errorMsg, "error")
                            .then(() => {
                                window.location.href = '/';
                            });
                    })
                } else if (r.status == 400) {
                    isErrorHandled = true;
                    if (!noError_) {
                        // ShowAlert("", 'There was an error processing your request.', "error");

                        r.text().then(resp => {
                            ShowAlert("", resp, "error");
                        });
                    }
                }

                if (r.status != 200 && !isErrorHandled) {
                    console.log(url_);
                    if (requestName_ != '' && r.status != 400 && r.status != 401) {
                        if (!noError_) {
                            ShowAlert("", 'Sorry! we were not able to process your request. ', "error");
                        }
                    }
                }

                !hideLoader && !noAutoCloseBlocker && ShowBlocker(false);
                hideLoader && showCustomLoader && ShowCustomBlocker(false);
                return r;
            });
        request.catch(x => {
            !hideLoader && !noAutoCloseBlocker && ShowBlocker(false);
            hideLoader && showCustomLoader && ShowCustomBlocker(false);

            ShowAlert("", 'Sorry! we were not able to process your request. ', "error");
        });

        return request;
    }
}