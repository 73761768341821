import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { Button } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

interface IDataRow {
    [column: string]: string | number | Date | any;
}

export interface DownloadCsvProps<D> {
    columns: TableColumn<D>[];
    data: D[];
}

export interface DownloadCsvState {

}


class DownloadCsv<D extends IDataRow, C> extends React.Component<DownloadCsvProps<D>, DownloadCsvState> {

    constructor(props_: DownloadCsvProps<D>) {
        super(props_);

        this.state = {}
    }

    public render() {
        return (
            <>
                {/* <Button onClick={e => {
                    this.downloadCSV(this.props.data);
                }}>Export</Button> */}
                <Button color="primary" style={{
                    minWidth: "unset",
                    marginLeft: '40px'
                }}
                    onClick={(ev_) => {
                        this.downloadCSV(this.props.data);
                    }}
                >
                    <DownloadForOfflineIcon fontSize="large" color='secondary' />
                </Button>
            </>
        )
    }

    private downloadCSV(array: D[]) {
        const link = document.createElement('a');
        // let csv = this.convertArrayOfObjectsToCSV(array);
        let csv = this.toCsv(this.pivot(array));
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    private convertArrayOfObjectsToCSV(array: D[]) {
        let result: any;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    private pivot(arr: D[]) {
        var mp = new Map();
        
        function setValue(a: any[], path: any[], val: any) {
            if (Object(val) !== val) { // primitive value
                var pathStr = path.join('.');
                var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
                a[i] = val;
            } else {
                for (var key in val) {
                    setValue(a, key == '0' ? path : path.concat(key), val[key]);
                }
            }
            return a;
        }
        
        var result = arr.map( obj => setValue([], [], obj) );
        return [[...mp.keys()], ...result];
    }
    
    private toCsv(arr: D[][]) {
        return arr.map( row => 
            row.map ( (val: any) => isNaN(val) ? JSON.stringify(val) : +val ).join(',')
        ).join('\n');
    }
}

export default DownloadCsv;