import Swal, { SweetAlertIcon } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function ShowAlert(title: string, message: string, type: any) {
    return withReactContent(Swal).fire(title, message, type).then(result => {
        return result.value;
    });
}

export function alertClose() {
    return Swal.close();
}
