import React from 'react';

let loaderState: boolean[] = [];
let customLoaderState: boolean[] = [];

export function ShowBlocker(blockerState_: boolean) {
    if (blockerState_) {
        loaderState.push(blockerState_);
    } else if (loaderState.length) loaderState = loaderState.slice(0, -1);
}

export function ShowCustomBlocker(blockerState_: boolean) {
    if (blockerState_) {
        customLoaderState.push(blockerState_);
    } else if (customLoaderState.length) customLoaderState = customLoaderState.slice(0, -1);
}

class LoaderComponent extends React.Component<ILoaderProps, ILoaderState> {
    constructor(props: ILoaderProps) {
        super(props);

        this.state = {
            activeLoader: true
        }
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({
                activeLoader: (loaderState.length > 0 || customLoaderState.length > 0)
            });
        }, 500);
    }

    public render() {
        if (!this.state.activeLoader) return null;
        return (
            <div id="spinnerScreen">
                <div className="sk-folding-cube">
                    <div className="sk-cube1 sk-cube"></div>
                    <div className="sk-cube2 sk-cube"></div>
                    <div className="sk-cube4 sk-cube"></div>
                    <div className="sk-cube3 sk-cube"></div>
                </div>
                {/* <div></div> */}
            </div>
        )
    }
}

interface ILoaderProps { }

interface ILoaderState {
    activeLoader: boolean
 }

export default LoaderComponent;