import BaseService from "./BaseService";

export default class DeleteAccountService extends BaseService {
    public static deleteAccount(postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'support/delete/account', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });
    }
}