import React from 'react';
import { ShowAlert } from '../../Component/ShowAlert';


interface CardSuccessProps { }
interface CardSuccessState { }

class CardSuccess extends React.Component<CardSuccessProps, CardSuccessState> {


    constructor(props_: CardSuccessProps) {
        super(props_);
    }

    componentDidMount(): void {
        // ShowAlert("Success", "You successfully added your card", "success");
    }

    public render() {
        return (
            <></>
        )
    }
}

export default CardSuccess;