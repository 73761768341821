import React from 'react';
import './TransactionBreakdown.css'; // Import your CSS file
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Label } from '@mui/icons-material';

// Props interface for the component
interface TransactionBreakdownProps {
    mobileWalletTransactions: number;
    bankTransactions: number;
    billPayTransactions: number;
}

// Transaction breakdown component
const TransactionBreakdown: React.FC<TransactionBreakdownProps> = ({
    mobileWalletTransactions,
    bankTransactions,
    billPayTransactions,
}) => {
    return (
        // <div className="transaction-breakdown" style={{  backgroundColor:'yellow', display:'flex', flexDirection:'column' }}>

        <div style={{}} >
            <div >
                <h3 className='d-title' style={{ textAlign: 'center', paddingTop: '10px' }}>Transaction Types Breakdown</h3>
                <div style={{ display: 'flex', flexDirection: 'column', height: '300px', justifyContent: 'center', marginTop: '20px' }}>
                    <div style={{ padding: '30px' }}>
                        <div className="transaction-types"  >
                            <div className="transaction-type">
                                <div className="icon">{'📱'}</div>
                                <p className='dashboard-widget-lable1'>Mobile Money</p>
                                <p className="transaction-value">{mobileWalletTransactions}</p>
                            </div>
                            <div className="transaction-type">

                                <div className="icon">{'🏦'}</div>
                                <p className='dashboard-widget-lable1'>Bank Deposit</p>
                                <p className="transaction-value">{bankTransactions}</p>
                            </div>
                        </div>
                        <div className="transaction-type">
                            <div className="icon">{'🧾'}</div>
                            <p className='dashboard-widget-lable1'>Pay Bills</p>
                            <p className="transaction-value">{billPayTransactions}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div >
                <h3 className='d-title' style={{ textAlign: 'center', margin: '10px', }}>Total Number of Users</h3>
            </div>
            <div style={{ backgroundColor: 'yellow', flex: 1 }}>
                <div className="transaction-types" style={{}} >
                    <div className="transaction-type">
                        
                        <div className="icon">{'📱'}</div>
                        <p className='dashboard-widget-lable1'>Mobile Money</p>
                        <p className="transaction-value">{mobileWalletTransactions}</p>
                    </div>
                    <div className="transaction-type">
                       
                        <div className="icon">{'🏦'}</div>
                        <p className='dashboard-widget-lable1'>Bank Deposit</p>
                        <p className="transaction-value">{bankTransactions}</p>
                    </div>
                </div>
                <div className="transaction-type">
                    <div className="icon">{'🧾'}</div>
                    <p className='dashboard-widget-lable1'>Pay Bills</p>
                    <p className="transaction-value">{billPayTransactions}</p>
                </div>
            </div> */}
        </div>
        // </div>
    );
};
export default TransactionBreakdown;