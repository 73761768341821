import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';

// Register necessary components for the chart
ChartJS.register(ArcElement, Tooltip, Legend);

// Define a generic type for the data prop
interface PieChartData<T extends string | number> {
    label: T;
    value: number;
}

interface PieChartProps<T extends string | number> {
    data: PieChartData<T>[];
    title: string;
    colorMapping?: Record<T, string>; // Optional mapping for custom colors
    isMoney?:Boolean
}

const PieChart = <T extends string | number>({ data, title, colorMapping,isMoney }: PieChartProps<T>) => {
    // Transform the data to fit Chart.js format
    const chartData = {
        labels: data.map((item) => { return ( `${item.label}-${ isMoney ?  item.value? item.value .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }):0.00:item.value ?? 0}`)}), // Labels
        datasets: [
            {
                label: 'Value',
                data: data.map((item) => item.value), // Values
                backgroundColor: data.map((item) => colorMapping?.[item.label] || 'rgba(34, 139, 34, 0.6)'), // Custom or default colors
                borderColor: data.map((item) => colorMapping?.[item.label] || 'rgba(34, 139, 34, 0.8)'), // Custom or default borders
                borderWidth: 1, // Border width
            },
        ],
    };

    // Chart options with custom title styling
    const options = {
        responsive: true,
        plugins: {
           
        },
        maintainAspectRatio: false, // Disable aspect ratio to allow custom size
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '300px', width:'300px' }}>
            <Pie data={chartData} options={options} />
        </div>
    );
};

export default PieChart;