export function amountFormat(input: number, local_: string, currency_: string) {
    const formatter = new Intl.NumberFormat(local_, {
        style: 'currency',
        currency: currency_,

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    // console.log(formatter.format(input));

    return formatter.format(input).replace('GH', '');
}
