import React from 'react';
import { loadFromLocalStorage } from '../../Common/Validation/LocalStorage';
import DataGrid from '../../Component/DataGrid/DataGrid';
import './TransactionLogs.css';
import { TableColumn } from 'react-data-table-component';
import TransactionService from '../../Services/TransactionService';
import { amountFormat } from '../../Component/helper';
import moment from 'moment';
import { ITransactionLogs, TransactionLogsProps, TransactionLogsState } from './Interface';
import TransactionDetails from './TransactionDetails/TransactionDetails';
import CustomMaterialMenu from '../../Component/DataGrid/Plugins/CustomMaterialMenu/CustomMaterialMenu';
import { ShowAlert } from '../../Component/ShowAlert';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import hacker from '../../Images/hacker.png';

class TransactionLogs extends React.Component<TransactionLogsProps, TransactionLogsState> {
    

    private tableColumns: TableColumn<ITransactionLogs>[] = [
        {
            name: 'Transaction Id',
            selector: row => row.payment_transaction_id,
            sortable: false,
            id: "TransactionId",

        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            id: "Name",

        },
        {
            name: 'Recipient',
            selector: row => row.recipient_name,
            sortable: true,
            id: "Recipient",

        },
        {
            name: 'Paid Amount',
            selector: row => amountFormat(row.paid_amount, 'en-US', 'USD'),
            sortable: true,
            id: "PaidAmount"
        },
        {
            name: 'Status',
            selector: row => row.payment_status,
            sortable: true,
            id: "status"
        },
        {
            name: 'Compliance',
            selector: row => row.compliance,
            sortable: true,
            id: "compliance",
            cell: row => <div className='center-cell'>{row.compliance ? <SpeakerNotesOffIcon /> : '-'}</div>
        },
        {
            name: 'Fraud Detection',
            selector: row => row.fraud_detection,
            sortable: true,
            id: "fraud_detection",
            cell: row => <div className='center-cell'>{row.fraud_detection ? <img src={hacker} alt="hacker" /> : '-'}</div>
        },
        {
            name: 'Date',
            selector: row => moment(row.payment_time).format('MM-DD-YYYY hh:mm A'),//hh:mm A on Do MMMM YYYY
            sortable: true,
            id: "date"
        },
        {
            cell: row => <CustomMaterialMenu
                fontSize="small"
                multiPopoverItems={row.fraud_detection ? [
                    {
                        key: "unMarkFraud",
                        label: "Unmark Fraud"
                    }
                ]: [{
                    key: "markFraud",
                    label: "Mark Fraud"
                }]}
                onPopoverClick={(key_: string, label_: string) => {
                    this.updateFraud(row, key_, label_);
                    // alert(key_);
                    /*this.setState({
                        popupState: true,
                        selectedRow: row
                    })*/
                }}
                disabled={row.payment_status == "refunded"}
            />,
            allowOverflow: true,
            button: true,
            width: '56px'
        }
    ]

    constructor(props_: TransactionLogsProps) {
        super(props_);

        let URLParams = new URLSearchParams(window.location.search);
        let transactionId = URLParams.get("id");


        this.state = {
            accessToken: "",
            transactionData: [],
            currentRowsPerPage: 10,
            currentPage: 1,
            totalRows: 100,
            searchText: transactionId ? transactionId : ""
        }
    }

    async componentDidMount() {
        console.log(this.state.searchText);
            const accesstoken_ = await loadFromLocalStorage('accessToken');

            if (accesstoken_) {
                this.setState({
                    accessToken: accesstoken_.toString()
                }, () => {
                    this.getTransactionHistory();
                })
    
            }
            else {
                localStorage.clear();
                window.location.href = '/'
            }
    }


    public render() {
        return (
            <div className='body-sub-contianer table-container'>
                {/* <h1>Transaction Logs</h1> */}
                <DataGrid
                    title='Transaction Logs'
                    search='Transaction Logs'
                    defaultSearch={this.state.searchText}
                    onSearch={(searchText_: string) => {
                        this.setState({
                            searchText: searchText_,
                            currentPage: 1
                        }, () => {
                            this.getTransactionHistory();
                        })
                    }}
                    columns={this.tableColumns}
                    data={this.state.transactionData}
                    expandableRows
                    onChangePage={(page: number, totalRows: number) => {
                        console.log(page, totalRows);
                        this.setState({
                            currentPage: page
                        }, () => {
                            this.getTransactionHistory();
                        })
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage_: number, currentPage_: number) => {
                        console.log(currentRowsPerPage_, currentPage_);
                        this.setState({
                            currentRowsPerPage: currentRowsPerPage_,
                            currentPage: currentPage_
                        }, () => {
                            this.getTransactionHistory();
                        })
                    }}
                    pagination
                    paginationTotalRows={this.state.totalRows}
                    expandedComponent={(data_: any) => <TransactionDetails
                        details={data_.data}
                        accessToken={this.state.accessToken}
                        onRefundDone={(pageRefresh: boolean) => {
                            if (pageRefresh) {
                                this.getTransactionHistory();
                            }
                        }} />}
                    exportCsv
                />
            </div>
        )
    }

    private getTransactionHistory() {
        let pageIndex: number = (this.state.currentPage - 1);
        let pagination: any = {
            "pageNumber": (pageIndex * this.state.currentRowsPerPage),
            "recordPerPage": this.state.currentRowsPerPage
        }

        if (this.state.searchText) {
            pagination["searchText"] = this.state.searchText
        }

        TransactionService.getTransactionHistory(this.state.accessToken, pagination).then(tr => {
            if (tr.ok) {
                tr.json().then(resp => {
                    this.setState({
                        transactionData: resp.transactionList,
                        totalRows: resp.pageCount

                    }, () => {
                        console.log(this.state.transactionData);
                    })
                });
            }
        });
    }

    private async updateFraud(row_: ITransactionLogs, key_: string, label_: string) {
        let postdata: any = {
            "transactionId": row_.payment_transaction_id,
            "fraudDetection": key_ == "markFraud"
        }

        let tr = await TransactionService.updateFraud(this.state.accessToken, postdata);
        let message = key_ == "markFraud" ? "fraudulent" : "not fraudulent";

        if (tr.ok) {
            ShowAlert("PassportPay", `The transaction has been marked as ${message}.`, 'success').then(() => {
                this.getTransactionHistory();
            });
        }
    }
}

export default TransactionLogs;