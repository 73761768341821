import React from 'react';
import { Box, Button, TextField, MenuItem, Select, InputLabel, FormControl, SelectChangeEvent } from '@mui/material';
export enum Status {
    RequestRegistered = 'Request Registered',
    InProgress = 'In Progress',
    Resolved = 'Resolved',
    Cancelled = 'Cancelled'
}
interface ComplianceFormProps {
    onSubmit: (formData: ComplianceFormData) => void;
    initialData: ComplianceFormData;
}

interface ComplianceFormData {
    subject: string | undefined;
    description: string | undefined;
    command: string | undefined;
    status: Status;
    ticketNumber:string;
    customerName: string;
    customerEmail: string;
    customerMobile: string;
}

interface ComplianceFormState extends ComplianceFormData { }

class ComplianceForm extends React.Component<ComplianceFormProps, ComplianceFormState> {
    constructor(props: ComplianceFormProps) {
        super(props);

        this.state = {
            ticketNumber: props.initialData.ticketNumber,
            subject: props.initialData.subject,
            description: props.initialData.description,
            command: props.initialData.command,
            status: props.initialData.status,
            customerName: props.initialData.customerName,
            customerEmail: props.initialData.customerEmail,
            customerMobile: props.initialData.customerMobile
        };
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState({ [name]: value } as Pick<ComplianceFormState, keyof ComplianceFormState>);
    };

    handleSelectChange = (event: SelectChangeEvent<Status>) => {
        this.setState({ status: event.target.value as Status });
    };

    handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        this.props.onSubmit(this.state);
    };

    render() {
        console.log("default value", this.state.status)
        return (
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: '80%',
                }}
                onSubmit={this.handleSubmit}
            >

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '50%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                            <label className='big-title-lbl'>Request Type: </label>
                            <label className='big-title-lb' style={{ paddingLeft: '10px' }}> {this.state.subject} </label>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '5px' }}>
                            <label className='big-title-lbl'>Description: </label>
                            <label className='big-title-lb' style={{ color: 'gray', paddingLeft: '10px' }}> {this.state.description} </label>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '50%', paddingLeft: '7px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                            <label className='big-title-lbl'>Customer's Email Id: </label>
                            <label className='big-title-lb' style={{ paddingLeft: '10px' }}> {this.state.customerEmail} </label>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '5px' }}>
                            <label className='big-title-lbl'>Customer's Mobile Number: </label>
                            <label className='big-title-lb' style={{ paddingLeft: '10px' }}> {this.state.customerMobile} </label>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '5px', gap: '16px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <label className='big-title-lbl' style={{ textAlign: 'left', paddingBottom: '10px' }}>Comments: </label>
                        <TextField
                            name="command"
                            value={this.state.command}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            color={'secondary'}
                            fullWidth
                            multiline
                            rows={5}
                        />
                    </div>
                    <FormControl variant="outlined" fullWidth>
                        <label className='big-title-lbl' style={{ textAlign: 'left', paddingBottom: '10px' }}>Status: </label>
                        <Select
                            value={this.state.status}
                            onChange={this.handleSelectChange}
                            color='secondary'
                            style={{ textAlign: 'left' }}
                        >
                            {Object.values(Status).map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <Button variant="contained" color='secondary' type="submit" sx={{ width: '300px', alignSelf: 'center' }}>
                    Submit
                </Button>
            </Box>
        );
    }
}

export default ComplianceForm;