import React from 'react';
import { FaParking, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ReactDOM from 'react-dom';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Popper, Grow, Paper, ClickAwayListener, MenuList, Avatar, Drawer, Grid, Button } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import './NavBar.css';
import SettingDialogPopop from './SettingDialog/SettingDialogPopop';
import { loadFromLocalStorage } from '../../Common/Validation/LocalStorage';

interface NavBarTopProps { }
interface NavBarTopState {
    userDetails: UserDetailsModel,
    platformFee: boolean
    // isNotificationOpened: boolean,
    // drawerId: number,
    // isScheduleOpened: boolean
}

interface UserDetailsModel {
    accessToken: string;
    first_name: string;
    last_name: string;
    email: string;
    fullName: string;
    mobile_number: string;
}


class NavBarTop extends React.Component<NavBarTopProps, NavBarTopState> {
    constructor(props: NavBarTopProps) {
        super(props);

        this.state = {
            userDetails: {
                accessToken: '',
                fullName: '',
                email: '',
                first_name: '',
                last_name: '',
                mobile_number: ''
            },
            platformFee: false
            // isNotificationOpened: false,
            // drawerId: 0,
            // isScheduleOpened: false
        };
    }

    async componentDidMount() {
        const _accessToken = await loadFromLocalStorage('accessToken');
        let _localData = localStorage.getItem('profile');

        if (_localData != null && _localData !== '' && _accessToken != null && _accessToken != '') {
            let _data = JSON.parse(_localData);

            let _userDetails: UserDetailsModel = _data;
            _userDetails.fullName = `${_userDetails.first_name} ${_userDetails.last_name}`;
            _userDetails.accessToken = _accessToken.toString()

            this.setState({
                userDetails: _userDetails
            });
        }
    }

    public render() {
        return (
            <div>
                <TopHeaderSource
                    userInfo={this.state.userDetails}
                    popupState={(s_) => {
                        this.setState({
                            platformFee: s_
                        })
                    }}
                />
                {
                    this.state.platformFee && this.state.userDetails.accessToken && <SettingDialogPopop
                        open={this.state.platformFee}
                        onClose={(state: boolean, action: string) => {
                            this.setState({
                                platformFee: false
                            })
                        }}
                        accessToken={this.state.userDetails.accessToken}
                    />
                }
            </div >
        )
    }
}

function TopHeaderSource(props: { userInfo: UserDetailsModel, popupState: (s_: boolean) => void }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogOut = () => {
        localStorage.clear();
        window.location.href = "/";
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSetting = () => {
        setAnchorEl(null);
        if (props.popupState) {
            props.popupState(true);
        }
    };


    const getUserProfileText = () => {
        let userAlpha = '';
        if (props.userInfo.fullName !== null && props.userInfo.fullName != '') {
            let userNameArray = props.userInfo.fullName.split(' ');
            let userAlphaList: any[] = [];

            for (let user of userNameArray) {
                userAlphaList.push(user[0]);
            }

            /*for (let i = 0; i < userNameArray.length; i++) {
                userAlphaList.push(userNameArray[i][0]);
            }*/

            userAlpha = userAlphaList.toString().replace(/,/g, '');
        }

        return userAlpha;
    }

    const stringToColor = (string: string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }


    return (
        <AppBar position="static" className='top-header-container'>
            <Toolbar className='header-container'>
                <div className="header-container-sub">
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="default"
                    >
                        <Avatar className='profile-icon' style={{ background: stringToColor(getUserProfileText()) }}>{getUserProfileText()}</Avatar>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        className="profile-popup"
                        anchorEl={anchorEl}
                        elevation={0}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleSetting}>Exchange Rate</MenuItem>
                        <hr />
                        {/* <MenuItem onClick={handleClose}>Go to prioritypassport</MenuItem> */}
                        <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                    </Menu>

                </div>
            </Toolbar>
        </AppBar>
    )
}

export default NavBarTop;