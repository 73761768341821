import React from 'react';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import './Dashboard.css';
import { CallMade, DateRange, MonetizationOn, Person3, QueryBuilder, Repeat, SignalCellularAlt, Support } from '@mui/icons-material';
import { loadFromLocalStorage, saveToLocalStorage } from '../../Common/Validation/LocalStorage';
import AddCardService from '../../Services/AddCardService';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import Drawer from '@mui/material/Drawer';
import BigButton from '../../Component/BigButton';
import DashboardService from '../../Services/DashboardService';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Popover from '@mui/material/Popover';
import { amountFormat } from '../../Component/helper';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import UserMetrics from '../../Component/UserMetrics/UserMetrics';
import { Metric } from '../../Component/UserMetrics/UserMetricsModel';
//import { TransactionSuccessRate } from '../../Component/UserMetrics/UserMetricsModel';
import TransactionDataChart from '../../Component/TransactionDataChart/TransactionDataChart';
import TransactionBreakdown from '../../Component/TransactionBreakdown/TransactionBreakdown';
import TransactionSuccessRate from '../../Component/TransactionSuccessRate/TransactionSuccessRate';
import { TSRModel } from '../../Component/TransactionSuccessRate/TransactionSuccessRateModel';
import { Box, Button, Icon, styled, Tab, Tabs } from '@mui/material';
import TabPanel from '../../Component/TabPanel/TabPanel';
import { greenTheme } from '../../Themes/GreenTheme';
import BarChart from '../../Component/BarChart/BarChart';
import PieChart from '../../Component/PieChart/PieChart';
import TransactionFees from '../../Component/TransactionFees/TransactionFees';
import StatCard from '../../Component/StatCard/StatCard';
import BlockIcon from '@mui/icons-material/Block'; // Example icon for Fraudulent Transactions
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Example icon for Amount Involved
import SupportTickets from '../../Component/SupportTickets/SupportTickets';
import { STModel } from '../../Component/SupportTickets/SupportTicketsModal';
import IconBox from '../../Component/IconBox/IconBox';
import { ShowAlert } from '../../Component/ShowAlert';

interface DashboardProps { }


interface DashboardWidget {
    money_credited: number | null,
    sender_amount: number | null,
    destination_amount: number | null,
    platformfees: number,
    exchangemarkup: number | null
}

interface DashboardWidgetItem {
    title: string;
    value: number;
    iconName: string;
    key: string;
}

interface Transaction {
    country: string;
    transaction_count: number;
}
interface RevenueByTypes {
    transaction_types: string;
    transaction_value: number;
}

interface FeeDataModel {
    title: string;
    amount: string;
    icon: string;
    subtitle?: string;
}
interface SupportIssues {
    title: string;
    amount: string;
    icon: string;
}
type SystemUptimeModel = {
    title: string;
    value: string;
    subValue?: string;
};
type userActivityTransactionModel = {
    mostCommonTransactionTimes: string;
    userRetentionRates: string;
}
interface AppUsersModel {
    platfrom: string;
    users: string;
    icon: string;
}

interface TransactionVolumeModel {
    region: string,
    volume: number
}

interface RegionWiseRevenueModel {
    region: string,
    total_revenue: string
}

interface ActiveUserDevice {
    device_type: string;
    count: number;
    icon?: string;
}

interface VersionDistributionModel {
    version_number: string;
    percentage: string;
}

interface TransactionsDistributionTimeModel {
    name: string,
    avgtime: string

}

interface TransactionDistributionTime {
    name: string;
    avgtime: string;
}

interface ApiResponse {
    transactions_average_time: string;
    transactions_distribution_time: TransactionDistributionTime[];
}

interface DashboardState {
    accessToken: string;
    dashboardFilter: string[];
    selectedFilterIndex: number;
    filterDateString: string;
    selectedDateRange: {
        startDate: Date;
        endDate: Date;
    };
    filteredDate: {
        startDate: Date;
        endDate: Date;
    };
    dateSelectionDrawer: boolean;
    dashboardWidget: DashboardWidget;
    dashboardWidgetItem: DashboardWidgetItem[];
    calendarPopOveropen: boolean;
    popOverTargetElement: HTMLElement | null;
    metricsData: Metric[];
    transactionSuccessData: TSRModel[];
    transactionProcessTime: TSRModel[];
    tabValue: number,
    transactionData: Transaction[];
    revenueByTypes: RevenueByTypes[];
    fees: FeeDataModel[];
    supportIssues: SupportIssues[];
    supportTicketMatrics: STModel[]
    systemUptime: SystemUptimeModel[];
    userActivityTransaction: userActivityTransactionModel[]
    appUsers: AppUsersModel[];
    activeUsers: number;
    newUsers: number;
    totalNoOfTranscation: number;
    averageTranscation: string;
    mobileWallet: number,
    bankTransfer: number,
    billPay: number,
    transactionVolume: TransactionVolumeModel[];
    totalRevenue: number,
    mobileWalletTotalRevenue: number,
    bankTotalRevenue: number,
    billPayTotalRevenue: number,
    regionWiseRevenue: RegionWiseRevenueModel[]
    flagComplianceReview: number,
    complianceReviewOutcomes: number
    fraudulentTransactions: number,
    fraudulentAmountInvolved: number,
    fraudulent: FeeDataModel[],
    activeUsersDevice: ActiveUserDevice[],
    versionDistribution: VersionDistributionModel[],
    uptimePercentage: number,
    downtime: number,
    transactionsAverageTime: string,
    transactionsDistribution: TransactionsDistributionTimeModel[]
    transactionsTypeDD: string[],
    transactionsDistributionTime: { name: string; avgtime: string }[];
    mostCommonTransactionTimes: string;
    userRetentionRates: string;

}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
    private calendarInputElm = React.createRef<HTMLElement>();

    constructor(props_: DashboardProps) {
        super(props_);

        this.state = {
            accessToken: "",
            dashboardFilter: ["Day", "Month", "Year", "Custom"],
            selectedFilterIndex: 0,
            // filterDateString: `${moment(new Date()).format('MM-DD-YYYY')} &#8212; ${moment(new Date()).format('MM-DD-YYYY')}`,
            filterDateString: `${moment(new Date()).format('MM-DD-YYYY')}`,
            dateSelectionDrawer: false,
            filteredDate: {
                startDate: new Date(),
                endDate: new Date()
            },
            selectedDateRange: {
                startDate: new Date(),
                endDate: new Date()
            },
            dashboardWidget: {
                destination_amount: 0,
                exchangemarkup: 0,
                money_credited: 0,
                platformfees: 0,
                sender_amount: 0
            },
            dashboardWidgetItem: [
                {
                    title: "Amount collected",
                    value: 0,
                    iconName: "CallReceivedIcon",
                    key: "money_credited"
                },
                {
                    title: "Amount transmitted",
                    value: 0,
                    iconName: "CallMade",
                    key: "sender_amount"
                },
                {
                    title: "Fees collected",
                    value: 0,
                    iconName: "AccountBalanceWalletIcon",
                    key: "platformfees"
                },
                {
                    title: "Markup collected",
                    value: 0,
                    iconName: "CurrencyExchangeIcon",
                    key: "exchangemarkup"
                },
            ],
            calendarPopOveropen: false,
            popOverTargetElement: null,
            metricsData: [
                { title: 'Active Users (Daily)', value: 1000 },
                { title: 'Active Users (Weekly)', value: 7500 },
                { title: 'Active Users (Monthly)', value: 30000 },
                { title: 'New User Sign-ups (Daily)', value: 150 },
                { title: 'New User Sign-ups (Weekly)', value: 1000 },
                { title: 'New User Sign-ups (Monthly)', value: 4000 },
            ],
            transactionSuccessData: [
                { title: 'Successful ', value: "0%" },
                { title: 'Failed', value: "0%" }
            ],
            tabValue: 0,
            transactionData: [
                { country: "Ghana", transaction_count: 80 },
                { country: "USA", transaction_count: 50 },
                { country: "India", transaction_count: 30 },
            ],
            revenueByTypes: [],
            fees: [],
            supportIssues: [],
            supportTicketMatrics: [],
            systemUptime: [
                {
                    title: "Uptime Percentage",
                    value: "98%",
                    subValue: "+2%"
                },
                {
                    title: "Downtime",
                    value: "5",
                    subValue: "-1"
                },
            ],
            userActivityTransaction: [],
            appUsers: [],
            activeUsers: 0,
            newUsers: 0,
            totalNoOfTranscation: 0,
            averageTranscation: "0",
            mobileWallet: 0,
            bankTransfer: 0,
            billPay: 0,
            transactionVolume: [],
            totalRevenue: 0,
            mobileWalletTotalRevenue: 0,
            bankTotalRevenue: 0,
            billPayTotalRevenue: 0,
            regionWiseRevenue: [],
            flagComplianceReview: 0,
            complianceReviewOutcomes: 0,
            fraudulentTransactions: 0,
            fraudulentAmountInvolved: 0,
            fraudulent: [],
            activeUsersDevice: [],
            versionDistribution: [],
            uptimePercentage: 0,
            downtime: 0,
            transactionsAverageTime: "",
            transactionsDistribution: [],
            transactionsTypeDD: [],
            transactionProcessTime: [],
            transactionsDistributionTime: [],
            mostCommonTransactionTimes: "-",
            userRetentionRates: "-"
        }

    }

    async componentDidMount() {
        console.log('Enered');
        const accesstoken = await loadFromLocalStorage('accessToken');

        if (accesstoken) {
            this.setState({
                accessToken: accesstoken.toString()
            }, () => {
                this.getUserDetails(accesstoken.toString());
            })

        }
        else {
            localStorage.clear();
            window.location.href = '/'
        }
        // this.setState({
        //     fees: [
        //         { title: 'Transaction Fees', amount: '$30', icon: '💰' },
        //         { title: 'Other Service Fees', amount: '$40', icon: '💰' },
        //     ]
        // });
        // this.setState({
        //     supportIssues: [
        //         { title: 'Transaction Issues', amount: '30', icon: '🛒' },
        //         { title: 'Account Issue', amount: '23', icon: '🔒' },
        //     ]
        // });
        // this.setState({
        //     appUsers: [
        //         { platfrom: 'Active User (iOS)', users: '30', icon: '' },
        //         { platfrom: 'Active User (Android)', users: '23', icon: '📱' },
        //         // { platfrom: 'App Version Distribution', users: `v1.2: 60%, v1.3: 30%, v1.4: 20%`, icon: '📊' },
        //     ]
        // });
        // this.setState({
        //     userActivityTransaction: [
        //         {
        //             mostCommonTransactionTimes: "Most Common Transaction Times",
        //             userRetentionRates: "10:00 AM to 12:00 PM",
        //         },
        //         {
        //             mostCommonTransactionTimes: "User Retention Rates",
        //             userRetentionRates: "80%",
        //         }
        //     ]
        // })

    }


    handleChange = (event: React.SyntheticEvent, newValue: number) => {
        this.setState({ tabValue: newValue });
    };
    transformTransactionData = () => {
        return this.state.transactionData.map(item => ({
            label: item.country,
            value: item.transaction_count,
        }));
    };

    transformRevenueByTypes = () => {
        return this.state.revenueByTypes.map(item => ({
            label: item.transaction_types,
            value: item.transaction_value,
        }));
    };

    // transformUserActivityTransaction = () => {
    //     return this.state.userActivityTransaction.map(item => ({
    //         title: item.mostCommonTransactionTimes,
    //         value: item.userRetentionRates,
    //     }));
    // };

    transformTranstionVolume = () => {
        return this.state.transactionVolume.map(item => ({
            label: item.region,
            value: item.volume,
        }));
    }


    transformRegionWiseRevenue = () => {
        return this.state.regionWiseRevenue.map(item => ({
            label: item.region,
            value: Number(item.total_revenue,)
        }));
    };


    transformActiveUsersDevice = (): SupportIssues[] => {
        return this.state.activeUsersDevice.map((item) => ({
            title: item.device_type,
            amount: item.count.toString(), // Convert number to string
            icon: item.device_type === 'android' ? '📱' : item.device_type === 'ios' ? '🍏' : '',
        }));
    };


    handleOptionSelect = (selectedValue: string) => {
        console.log('Selected value:', selectedValue);
        // You can perform any action here with the selected value
    };


    public render() {
        const transactionVolume = [1000, 2000, 3000]; // Example data
        const totalTransactions = [150, 250, 350]; // Example data
        const transactionByRegion = [
            { region: 'North America', volume: 1000 },
            { region: 'Europe', volume: 1500 },
            { region: 'Asia', volume: 2000 },
        ];
        const averageTransactionSize = [200, 250, 300]; // Example data
        const { tabValue } = this.state
        const CustomTabs = styled(Tabs)({
            backgroundColor: '#ffffff', // White background
            borderRadius: '25px',
            padding: '5px', // Adds spacing around tabs
            minHeight: 'unset', // Reset default min height
        });

        const StyledTab = styled(Tab)(({ theme }) => ({
            color: greenTheme.palette.primary.contrastText,
            borderRadius: '25px',
            paddingRight: '10px',
            paddingLeft: '10px',
            height: '5px',
            padding: '10px',
            '&.Mui-selected': {
                color: '#fff',
                backgroundColor: greenTheme.palette.secondary.main,
            },
            fontFamily: 'Montserrat'
        }));


        const groupedData = this.state.transactionData.reduce((acc, curr) => {
            if (!acc[curr.country]) {
                acc[curr.country] = 0;
            }
            acc[curr.country] += curr.transaction_count;
            return acc;
        }, {} as Record<string, number>);

        const chartData = Object.entries(groupedData).map(([country, transaction_count]) => ({
            country,
            transaction_count
        }));
        const colorMapping = {
            // Ghana: 'rgba(34, 139, 34, 0.6)',
            Ghana: 'rgb(239,170,104,255)',
            USA: 'rgb(135,108,109)',
            India: 'rgb(185,165,181)',
            "Bank Transfer": 'rgba(255, 206, 86, 0.6)',
            "Bill Pay": 'rgba(75, 192, 192, 0.6)',
            "Mobile Wallet": 'rgba(153, 102, 255, 0.6)',

        };
        const dynamicOptions = [
            { value: 'option1', label: 'All-Supported-Country' },
            { value: 'option2', label: 'Ghana' },
            { value: 'option3', label: 'India' },
            { value: 'option4', label: 'USA' },
        ];

        return (
            <div className='body-sub-contianer'>
                <div className='dashboard-filter'>
                    <h1>Dashboard</h1>
                    <div className='filtered-item'>
                        {/* <div className='filtered-tags' dangerouslySetInnerHTML={{ __html: this.state.filterDateString }} /> */}
                    </div>
                    <div className='filtered-option'>
                        {
                            this.state.dashboardFilter.map((item, index) => {
                                return (<div key={index}
                                    className={'filter-item ' + (this.state.selectedFilterIndex == index ? 'selected' : '')}
                                    onClick={() => {
                                        this.onFilterDate(index);
                                    }}>{item}</div>);
                            })
                        }
                        <div className='filtered-tags'>
                            <NavigateBeforeIcon className='nav-btn'
                                onClick={() => {
                                    this.navDate('prev');
                                }}
                                fontSize='medium' />
                            <span ref={this.calendarInputElm} onClick={(ev_) => {
                                this.setState({
                                    popOverTargetElement: ev_.target as HTMLElement
                                });
                            }}>{this.state.filterDateString}</span>
                            <NavigateNextIcon
                                className='nav-btn'
                                fontSize='medium'
                                onClick={() => {
                                    this.navDate('next');
                                }}
                            /></div>
                    </div>
                    <Popover
                        id={'simple-popover'}
                        open={this.state.popOverTargetElement != null}
                        anchorEl={this.state.popOverTargetElement}
                        onClose={() => {
                            this.setState({
                                popOverTargetElement: null
                            });
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <div className='popover-calendar'>
                            <DateRangePicker
                                ranges={[this.state.filteredDate]}
                                onChange={(ranges: any) => {
                                    console.log(ranges);
                                    this.handleSelectDate(ranges);
                                }}
                            />
                            <BigButton
                                disabled={false}
                                width='80%'
                                onClick={() => {
                                    this.applyDateRange();
                                }} buttonText="Apply" />
                        </div>
                    </Popover>
                </div>
                <div className='top-widgets'>
                    {
                        this.state.dashboardWidgetItem.map(item => {
                            let amountValue = this.state.dashboardWidget[item.key as keyof typeof this.state.dashboardWidget];
                            amountValue = amountValue ? amountValue : 0;
                            return (
                                <div className='dashboard-widget'>
                                    <div className='dashboard-widget-icon'>{this.getIcon(item.iconName)}</div>
                                    <div className='dashboard-widget-body'>
                                        <div className='dashboard-widget-lable1'>{item.title}</div>
                                        <div className='dashboard-widget-lable2'>{amountFormat(amountValue, 'en-US', 'USD')}</div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>


                <Box sx={{ marginTop: '20px', alignItems: 'center', overflow: 'auto' }}>
                    <Tabs
                        style={{ backgroundColor: 'white' }}
                        sx={{
                            display: 'flex',
                            padding: '5px',
                            width: 'fit-content',
                            borderRadius: '25px',
                            textTransform: 'none',
                            margin: 'auto'
                        }}
                        // centered={true}
                        value={tabValue}
                        onChange={this.handleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{ style: { display: 'none' } }} // Hides the underline
                    >
                        <StyledTab label="User and Transaction Metrics" id="tab-0" aria-controls="tabpanel-0" />
                        <StyledTab label="Financial Metrics " id="tab-1" aria-controls="tabpanel-1" />
                        <StyledTab label="Compliance and Risk Metrics " id="tab-2" aria-controls="tabpanel-2" />
                        <StyledTab label="Customer Support Metrics  " id="tab-3" aria-controls="tabpanel-3" />
                        <StyledTab label="Performance and System Metrics  " id="tab-4" aria-controls="tabpanel-4" />
                    </Tabs>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '10px', justifyContent: 'flex-end' }}>

                    </div>
                    <TabPanel value={tabValue} index={0}>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                                gridAutoRows: 'minmax(200px, auto)',
                                gap: '16px',  // Consistent gap across all screen sizes
                                margin: '10px',
                            }}
                        >
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px' }}>
                                <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>Total Number of Users</h3>
                                <IconBox icon={this.getIcon('user')} label={`Total number of active users`} value={this.state.activeUsers}></IconBox>
                                <IconBox icon={this.getIcon('user')} label={`Total number of newly user sign-ups`} value={this.state.newUsers}></IconBox>
                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px' }}>
                                <TransactionBreakdown
                                    mobileWalletTransactions={this.state.mobileWallet}
                                    bankTransactions={this.state.bankTransfer}
                                    billPayTransactions={this.state.billPay}
                                />
                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px' }}>
                                <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>Transaction Volume</h3>
                                <IconBox icon={this.getIcon('CallMade')} label={`Total number of transaction`} value={this.state.totalNoOfTranscation}></IconBox>
                                <IconBox icon={this.getIcon('CallMade')} label={`Average transaction`} value={`${this.state.averageTranscation}`} showAsFloat={true}></IconBox>
                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px' }}>
                                <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>Transaction Volume by Region</h3>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '300px', // The height of the parent container
                                    width: '100%', // Ensures the pie chart takes the full width of the parent
                                    padding: '10px', // Optional padding around the pie chart
                                    boxSizing: 'border-box', // Ensure padding doesn't affect the size
                                }}>
                                    {
                                        this.transformTranstionVolume().length > 0 ? (
                                            <PieChart
                                                data={this.transformTranstionVolume()}
                                                title=""
                                                colorMapping={colorMapping} // Optional: Pass the color mapping

                                            />
                                        ) : (
                                            <div style={{
                                                height: '210px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                textAlign: 'center',
                                            }}>
                                                <label className='dashboard-widget-lable1'>{`No Transaction(s) Volume by Region`}</label>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}  >
                                    <TransactionSuccessRate title={'Transaction Success Rate'} transactionSuccessRate={this.state.transactionSuccessData} LinkBTTitle='Reason' showLinkBT={false} showAtIndex={1} onReasonsClick={(index: number) => {
                                        console.log("index", index)
                                        ShowAlert("PassportPay", "Under development", 'warning')

                                    }} />
                                </div>
                            </div>

                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <div
                            style={{
                                display: 'grid',
                                // gridTemplateColumns: 'repeat(auto-fill, 370px)', // Fixed width for each grid item
                                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                                gridAutoRows: 'minmax(200px, auto)',
                                gap: '16px',  // Consistent gap across all screen sizes
                                margin: '10px',
                            }}
                        >
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {/* <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>Revenue</h3> */}
                                <div className="metric-cell" style={{ margin: '10px', height: '95%' }}>
                                    <div style={{ 'display': 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: '-20px' }}>
                                        <SignalCellularAlt sx={{ color: greenTheme.palette.secondary.main, fontSize: '32px' }} /><h3 className='d-title' style={{ textAlign: 'left' }}>Total Revenue</h3>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '80%' }}>
                                        {/* <label className='big-title-lbl'>{`${(this.state.totalRevenue ? this.state.totalRevenue : 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
`}</label> */}
                                        <label className='big-title-lbl'>{`$ ${this.state.totalRevenue ? this.state.totalRevenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`}</label>
                                        {/* <label className='big-title-lbl'>{`$ ${this.state.totalRevenue ? this.state.totalRevenue.toFixed(2) : 0}`}</label> */}
                                    </div>
                                </div>
                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px' }}>
                                <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>Revenue by Region</h3>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '300px', // The height of the parent container
                                    width: '100%', // Ensures the pie chart takes the full width of the parent
                                    padding: '10px', // Optional padding around the pie chart
                                    boxSizing: 'border-box', // Ensure padding doesn't affect the size
                                }}>
                                    {
                                        this.transformRegionWiseRevenue().length > 0 ? (
                                            <PieChart
                                                data={this.transformRegionWiseRevenue()}
                                                title="Revenue"
                                                colorMapping={colorMapping} // Optional: Pass the color mapping
                                                isMoney={true}
                                            />
                                        ) : (
                                            <div style={{
                                                height: '210px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                textAlign: 'center',
                                            }}>
                                                <label className='dashboard-widget-lable1'>{`No Revenue by Region`}</label>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px' }}>
                                <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>Revenue by Transaction Type</h3>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '300px', // The height of the parent container
                                    width: '100%', // Ensures the pie chart takes the full width of the parent
                                    padding: '10px', // Optional padding around the pie chart
                                    boxSizing: 'border-box', // Ensure padding doesn't affect the size
                                }}>
                                    {
                                        this.state.revenueByTypes.length > 0 ? (
                                            <PieChart
                                                data={this.transformRevenueByTypes()}
                                                title="Revenue"
                                                colorMapping={colorMapping} // Optional: Pass the color mapping
                                                isMoney={true}
                                            />
                                        ) : (
                                            <div style={{
                                                height: '210px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                textAlign: 'center',
                                            }}>
                                                <label className='dashboard-widget-lable1'>{`No Revenue by Transaction Type`}</label>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', }} >
                                    <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>Fees Collected</h3>
                                    <div >
                                        <TransactionFees fees={this.state.fees} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, 371px)', // Fixed width for each grid item
                                gridAutoRows: 'minmax(200px, auto)',
                                gap: '16px',  // Consistent gap across all screen sizes
                                // justifyContent: 'center',  // Center the grid items if there is extra space
                                margin: '10px',
                            }}
                        >
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', width: '371px' }}>
                                <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>Compliance</h3>
                                <IconBox icon={this.getIcon('CallReceivedIcon')} label='Number of Transactions Flagged for Compliance Review' value={this.state.flagComplianceReview ?? 0}></IconBox>
                                <IconBox icon={this.getIcon('CallMade')} label='Compliance Review Outcomes' value={this.state.complianceReviewOutcomes ?? 0}></IconBox>
                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', width: '400px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', }} >
                                    <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>Fraud Detection</h3>
                                    <div >
                                        <TransactionFees fees={this.state.fraudulent} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, 371px)', // Fixed width for each grid item
                                gridAutoRows: 'minmax(200px, auto)',
                                gap: '16px',  // Consistent gap across all screen sizes
                                // justifyContent: 'center',  // Center the grid items if there is extra space
                                margin: '10px',
                            }}
                        >
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', width: '371px' }}>
                                <SupportTickets title={'Customer Support Tickets'} columns={2} supportTicketsRate={this.state.supportTicketMatrics} />
                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', width: '371px' }}>
                                <div style={{ alignContent: 'center' }}>
                                    <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>Common Support Issues</h3>
                                </div>
                                <div className='dashboard-widget-body'>
                                    {
                                        this.state.supportIssues.length ? <TransactionFees fees={this.state.supportIssues} /> : <div style={{ height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <label className='dashboard-widget-lable1' >{`No Common Support Issue(s)`}</label>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>

                        <h1 style={{ textAlign: 'center', padding: '20px' }}> User Behavior and Engagement</h1>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, 371px)', // Fixed width for each grid item
                                gridAutoRows: 'minmax(200px, auto)',
                                gap: '16px',  // Consistent gap across all screen sizes
                                // justifyContent: 'center',  // Center the grid items if there is extra space
                                margin: '10px',
                            }}
                        >
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', width: '371px' }}>

                                <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>User Activity</h3>
                                <IconBox icon={this.getIcon('clock')} label={`Most Common Transaction Times`} value={this.state.mostCommonTransactionTimes}></IconBox>
                                <IconBox icon={this.getIcon('repeat')} label={`User Retention Rates`} value={this.state.userRetentionRates}></IconBox>

                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', width: '371px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', }} >
                                    <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>App Usage</h3>
                                    <div >
                                        {
                                            this.transformActiveUsersDevice().length ? <TransactionFees fees={this.transformActiveUsersDevice()} /> : <div style={{ height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <label className='dashboard-widget-lable1' >{`No App Usage`}</label>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', width: '371px' }}>
                                <h3 className='d-title' style={{ textAlign: 'center', margin: '10px' }}>Version Distribution</h3>
                                <div className='dashboard-widget-body'>
                                    {this.state.versionDistribution.length ? (
                                        <ul className='scrollable-list' style={{ padding: 0, margin: 0 }}>
                                            {this.state.versionDistribution.map((version, index) => (
                                                <li key={index} style={{ listStyleType: 'none', padding: 'px 0' }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center', // Center horizontally
                                                        alignItems: 'center', // Center vertically
                                                        width: '100%',
                                                    }}>
                                                        <label className='dashboard-widget-lable1' style={{ textAlign: 'center' }}>
                                                            {`Version: ${version.version_number} - Usage: ${version.percentage}`}
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div style={{
                                            height: '300px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center', // Center vertically
                                        }}>
                                            <label className='dashboard-widget-lable1' style={{ textAlign: 'center' }}>
                                                {`No Version Distribution`}
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={4}>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, 371px)', // Fixed width for each grid item
                                gridAutoRows: 'minmax(200px, auto)',
                                gap: '16px',  // Consistent gap across all screen sizes
                                // justifyContent: 'center',  // Center the grid items if there is extra space
                                margin: '10px',
                            }}
                        >
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', width: '371px' }}>
                                <TransactionSuccessRate title={'System Uptime'} transactionSuccessRate={this.state.systemUptime} showAtIndex={1} showLinkBT={false} LinkBTTitle='Incidents' onReasonsClick={(index: number) => {
                                    console.log("index", index);
                                    ShowAlert("PassportPay", "Under development", 'warning')
                                }} />
                            </div>
                            <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', width: '371px' }}>
                                <TransactionSuccessRate
                                    title="Transaction Processing Time"
                                    transactionSuccessRate={this.state.transactionProcessTime}
                                    showDPAtIndex={1}
                                    dropdownData={this.state.transactionsTypeDD}
                                    onDropdownChange={(selectedOption: string) => {
                                        const selectedTransaction = this.state.transactionsDistributionTime.find(
                                            (transaction) => transaction.name === selectedOption
                                        );

                                        if (selectedTransaction) {
                                            // Update the time distribution value based on selected dropdown option
                                            this.setState((prevState) => ({
                                                transactionProcessTime: prevState.transactionProcessTime.map((item, index) =>
                                                    index === 1 ? { ...item, value: selectedTransaction.avgtime } : item
                                                )
                                            }));
                                        }

                                        console.log("Selected Option:", selectedOption);
                                    }}

                                />
                            </div>

                        </div>
                    </TabPanel>
                </Box>


                <Drawer
                    anchor={'right'}
                    open={this.state.dateSelectionDrawer}
                // onClose={() => {
                //     this.setState({
                //         dateSelectionDrawer: false
                //     })
                // }}
                >
                    <div className='drawer-container'>
                        <div className='drawer-body'>
                            <h3>Custom Date</h3>
                            <DateRangePicker
                                ranges={[this.state.filteredDate]}
                                onChange={(ranges: any) => {
                                    this.handleSelectDate(ranges);
                                }}
                            />
                        </div>
                        <div className='drawer-footer'>
                            <BigButton
                                disabled={false}
                                width='100%'
                                onClick={() => {
                                    this.applyDateRange();
                                }} buttonText="Apply" />
                            <BigButton
                                disabled={false}
                                width='100%'
                                isCancel={true}
                                onClick={() => {
                                    this.setState({
                                        dateSelectionDrawer: false
                                    })
                                }} buttonText="Cancel" />
                        </div>
                    </div>
                </Drawer>
            </div>
        )
    }

    // private amountFormat(input: number) {
    //     const formatter = new Intl.NumberFormat('en-US', {
    //         style: 'currency',
    //         currency: 'USD',

    //         // These options are needed to round to whole numbers if that's what you want.
    //         //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //         //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    //     });

    //     console.log(formatter.format(input));

    //     return formatter.format(input);
    // }

    private getIcon(iconName: string) {
        if (iconName == "CallReceivedIcon") {
            return (<CallReceivedIcon fontSize="large" />)
        }
        else if (iconName == "CallMade") {
            return (<CallMade fontSize="large" />)
        }
        else if (iconName == "CurrencyExchangeIcon") {
            return (<CurrencyExchangeIcon fontSize="large" />)
        }
        else if (iconName == "AccountBalanceWalletIcon") {
            return (<AccountBalanceWalletIcon fontSize="large" />)
        }
        else if (iconName == "user") {
            return (<Person3 fontSize="large" />)
        }
        else if (iconName == "dollar") {
            return (<MonetizationOn fontSize="large" />)
        }
        else if (iconName == "clock") {
            return (<QueryBuilder fontSize="large" />)
        }
        else if (iconName == "repeat") {
            return (<Repeat fontSize="large" />)
        }


    }

    private getUserDetails(accessToken: string) {
        AddCardService.getUserDetails(accessToken).then(r => {
            if (r.ok) {
                this.getDashboardData();
            }
        });
    }

    private onFilterDate(item: number) {
        if (item === 3) {
            this.calendarInputElm.current?.click();
            // this.setState({
            //     dateSelectionDrawer: true
            // });
        }
        else {
            let date = moment(new Date()).format('MM-DD-YYYY');
            let filteredDate = this.state.filteredDate;
            switch (item) {
                case 0:
                    date = `${moment(new Date()).format('MM-DD-YYYY')}`;
                    filteredDate = {
                        startDate: new Date(),
                        endDate: new Date()
                    };
                    break;
                case 1:
                    filteredDate = {
                        startDate: moment(new Date()).startOf('month').toDate(),
                        endDate: moment(new Date()).endOf('month').toDate()
                    };
                    // &#8212;
                    date = `${moment(new Date()).startOf('month').format('MM-DD-YYYY')} to ${moment(new Date()).endOf('month').format('MM-DD-YYYY')}`;
                    break;
                case 2:
                    filteredDate = {
                        startDate: moment(new Date()).startOf('year').toDate(),
                        endDate: moment(new Date()).endOf('year').toDate()
                    };
                    date = `${moment(new Date()).startOf('year').format('MM-DD-YYYY')} to ${moment(new Date()).endOf('year').format('MM-DD-YYYY')}`;
                    break;
                // case 3:
                //     date = `${moment(new Date()).startOf('week').format('MM-DD-YYYY')} - ${moment(new Date()).format('MM-DD-YYYY')}`;
                //     break;
            }

            this.setState({
                selectedFilterIndex: item,
                filterDateString: date,
                filteredDate: filteredDate
            }, () => {
                this.getDashboardData();
            });
        }
    }

    private handleSelectDate(ranges: any) {
        let dateRange = this.state.filteredDate;
        dateRange.startDate = ranges.range1.startDate;
        dateRange.endDate = ranges.range1.endDate;

        this.setState({
            selectedDateRange: dateRange
        })
    }

    private applyDateRange() {
        let dateRange = this.state.selectedDateRange;

        let date = `${moment(dateRange.startDate).format('MM-DD-YYYY')} to ${moment(dateRange.endDate).format('MM-DD-YYYY')}`;
        this.setState({
            filteredDate: dateRange,
            filterDateString: date,
            dateSelectionDrawer: false,
            selectedFilterIndex: 3,
            popOverTargetElement: null
        }, () => {
            this.getDashboardData();
        });
    }

    private getDashboardData() {
        let dateRange = this.state.filteredDate;
        let postData = {
            startDate: `${moment(dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')}`,
            endDate: `${moment(dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss')}`
        };

        DashboardService.getDashbaordDetails(this.state.accessToken, postData).then(r => {
            if (r.ok) {
                r.json().then(resp => {
                    console.log("resp", resp);
                    this.setState({
                        dashboardWidget: resp
                    }, () => {
                        console.log(this.state.dashboardWidget);
                    })
                });
            }
        });
        DashboardService.getDashboardUserMetric(this.state.accessToken, postData).then(r => {
            if (r.ok) {
                r.json().then(resp => {
                    console.log("getDashbaordUserMetric", resp);
                    this.setState({
                        activeUsers: resp.active_users,
                        newUsers: resp.new_users,
                        totalNoOfTranscation: resp.number_of_transaction,
                        averageTranscation: resp.average_transaction,
                        mobileWallet: resp.mobile_wallet,
                        bankTransfer: resp.bank,
                        billPay: resp.bill_pay,
                        transactionVolume: resp.transaction_volume,
                        transactionSuccessData: [
                            { title: 'Successful Transactions', value: resp.success_transaction },
                            { title: 'Failed Transaction', value: resp.failed_transaction },
                            // { title: 'Top Failure Reasons', value: `Card Declined, Insufficient Funds` },
                        ]
                    })
                });
            }
        });
        DashboardService.getDashbaordFinancialMetric(this.state.accessToken, postData).then(r => {
            if (r.ok) {
                r.json().then(resp => {
                    this.setState({
                        totalRevenue: resp.total_revenue,
                        regionWiseRevenue: resp.region_wise_revenue,
                        revenueByTypes: resp.bill_pay_total_revenue || resp.mobile_wallet_total_revenue || resp.bank_total_revenue ? [
                            {
                                transaction_types: "Bill Pay",
                                transaction_value: resp.bill_pay_total_revenue
                            },
                            {
                                transaction_types: "Mobile Wallet",
                                transaction_value: resp.mobile_wallet_total_revenue
                            },
                            {
                                transaction_types: "Bank Transfer",
                                transaction_value: resp.bank_total_revenue
                            }

                        ] : [],
                        fees: [
                            {
                                title: 'Transaction Fees', amount: `$ ${(resp.transaction_fee ?? 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                    }`, icon: '💰'
                            },
                            { title: 'Other Service Fees', amount: `$ ${(resp.service_fee ?? 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, icon: '💰' },
                        ]
                    })
                });
            }
        });
        DashboardService.getDashboardComplianceRiskMetrics(this.state.accessToken, postData).then(r => {
            if (r.ok) {
                r.json().then(resp => {
                    this.setState({
                        flagComplianceReview: resp.flag_compliance_review,
                        complianceReviewOutcomes: resp.compliance_review_outcomes,
                        fraudulentTransactions: resp.fraudulent_transactions,
                        fraudulentAmountInvolved: resp.fraudulent_amount_involved,
                        fraudulent: [
                            {
                                title: `Fraudulent Transactions`, subtitle: "Total", amount: `${(resp.fraudulent_transactions ?? 0)}`, icon: '🚫'
                            },
                            { title: `Amount Involved`, subtitle: "USD", amount: `$ ${(resp.fraudulent_amount_involved ?? 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, icon: '💸' },
                        ]
                    })

                });
            }
        });
        DashboardService.getDashboardBehaviorAndEngagement(this.state.accessToken, postData).then(r => {
            if (r.ok) {
                r.json().then(resp => {
                    // Get the current date in the 'YYYY-MM-DD' format
                    const currentDate = new Date().toISOString().split('T')[0];
                    // Combine the current date with the UTC time from the API
                    const utcDateTimeString = `${currentDate}T${resp.user_activity.common_transaction_time}Z`;
                    // Create a Date object from the UTC string
                    const localTime = new Date(utcDateTimeString).toLocaleTimeString('en-US', {
                        hour12: false, // 24-hour format
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                    });
                    this.setState({
                        activeUsersDevice: resp.active_users_device,
                        versionDistribution: resp.version_distribution,
                        mostCommonTransactionTimes: localTime,
                        userRetentionRates: resp.user_activity.retention_percentage
                    })

                });
            }
        });
        DashboardService.getDashboardPerformanceSystemMetrics(this.state.accessToken, postData).then(r => {
            if (r.ok) {
                r.json().then(resp => {

                    const transactionProcessTime = [
                        { title: 'Average Time to Process Transaction', value: resp.transactions_average_time },
                        {
                            title: 'Time Distribution by Transaction Type',
                            value: resp.transactions_distribution_time.length > 0
                                ? resp.transactions_distribution_time[0].avgtime
                                : '-'  // Provide a default value like 'N/A' if no data is available
                        },
                    ];
                    this.setState({
                        transactionsAverageTime: resp.transactions_average_time,
                        transactionsDistribution: resp.transactions_distribution_time,
                        systemUptime: [
                            {
                                title: "Uptime Percentage",
                                value: `${resp.system_uptime.uptime_percentage ?? '-'}`,

                            },
                            {
                                title: "Downtime",
                                value: `${resp.system_uptime.downtime ?? '-'}`,

                            },

                        ],
                        transactionProcessTime,
                        transactionsDistributionTime: resp.transactions_distribution_time,
                        transactionsTypeDD: resp.transactions_distribution_time.map((item: { name: string; }) => item.name)
                    })

                });
            }
        });

        DashboardService.getDashboardCustomerSupportMetrics(this.state.accessToken, postData).then(r => {
            if (r.ok) {
                r.json().then(resp => {


                    this.setState({
                        supportTicketMatrics: [
                            {
                                title: 'Number of Support Tickets', value: resp.NumberOfSupportTickets ?? "-"
                            },
                            { title: 'Average Resolution', value: resp.AverageResolutionTime ?? "-" },
                            { title: 'Customer Satisfaction', value: resp.CustomerSatisfactionScores ?? "-" },
                        ],
                        supportIssues: [
                            { title: 'Transaction Issues', amount: resp.TransactionIssues ?? "-", icon: '🛒' },
                            { title: 'Account Issue', amount: resp.AccountIssues ?? "-", icon: '🔒' },
                        ]
                    })

                });
            }
        });

    }



    private navDate(nav: string) {
        let dateRange = this.state.filteredDate;
        let navType = nav == "prev" ? -1 : 1;

        // Day
        if (this.state.selectedFilterIndex == 0) {
            dateRange.startDate = moment(dateRange.startDate).add(navType, "days").toDate();
            dateRange.endDate = moment(dateRange.endDate).add(navType, "days").toDate();
        }
        // Month
        else if (this.state.selectedFilterIndex == 1) {
            dateRange.startDate = moment(dateRange.startDate).add(navType, "months").startOf('month').toDate();
            dateRange.endDate = moment(dateRange.endDate).add(navType, "months").endOf('month').toDate();
        }
        else if (this.state.selectedFilterIndex == 2) {
            dateRange.startDate = moment(dateRange.startDate).add(navType, "years").toDate();
            dateRange.endDate = moment(dateRange.endDate).add(navType, "years").toDate();
        }
        else if (this.state.selectedFilterIndex == 3) {
            let date1 = moment(dateRange.startDate);
            let date2 = moment(dateRange.endDate);
            let diff = Math.abs(date1.diff(date2, 'days')) + 1;

            dateRange.startDate = moment(dateRange.startDate).add((diff) * navType, "days").toDate();
            dateRange.endDate = moment(dateRange.endDate).add((diff) * navType, "days").toDate();
        }

        let dateString = `${moment(dateRange.startDate).format('MM-DD-YYYY')} to ${moment(dateRange.endDate).format('MM-DD-YYYY')}`;

        if (this.state.selectedFilterIndex == 0) {
            dateString = `${moment(dateRange.startDate).format('MM-DD-YYYY')}`;
        }

        this.setState({
            filterDateString: dateString,
            filteredDate: dateRange
        }, () => {
            this.getDashboardData();
        });
    }

}

export default Dashboard;