import React from 'react';
import './DeleteAccount.css';
import DeleteAccountService from '../../Services/DeleteAccountService';
import { ShowAlert } from '../../Component/ShowAlert';
import { ShowBlocker } from '../../Component/LoaderComponent';
// import { creditCardType } from 'credit-card-type';
import ValidationCheck from '../../Common/Validation/ValidationChecks';



interface DeleteAccountProps { }
interface DeleteAccountState {
    email: string;
    password: string;
    feedback: string;
}

class DeleteAccount extends React.Component<DeleteAccountProps, DeleteAccountState> {

    private formRef: any = null;

    constructor(props_: DeleteAccountProps) {
        super(props_);

        this.state = {
            email: "",
            password: "",
            feedback: ""
        }

        this.formRef = React.createRef();
    }

    public render() {
        return (
            <form className="delete-account" id="userDetails" ref={this.formRef} autoComplete='off'>
                <div className="form-header">
                    <h4 className="title">Delete your account</h4>
                </div>

                <div className="form-body">
                    <p>Sorry to see you go!</p>
                    <p>Please consider letting us know why would like to leave PassportPay. We will reach out to you for confirmation before deleting your account and personal information within 30 days.</p>
                    <div>Why are you deleting this account? (Optional)</div>
                    <textarea
                        placeholder='(OPTIONAL) FEEDBACK'
                        value={this.state.feedback}
                        onChange={(e) => {
                            this.setState({
                                feedback: e.target.value
                            })
                        }}
                    />
                    <div>Account credentials are required for security purposes.</div>
                    <input
                        type='email'
                        autoComplete='new-off'
                        placeholder='Email'
                        value={this.state.email}
                        onChange={(e) => {
                            this.setState({
                                email: e.target.value
                            })
                        }}
                    />
                    <input
                        type='password'
                        placeholder='Password'
                        value={this.state.password}
                        onChange={(e) => {
                            this.setState({
                                password: e.target.value
                            })
                        }}
                    />

                    <button type="button" onClick={() => {
                        this.submitForDeleteAccount();
                    }} className="proceed-btn">Delete</button>

                </div>
            </form>
        )
    }

    private async submitForDeleteAccount() {
        if (!this.state.email || !this.state.email) {
            ShowAlert("PassportPay", "Please enter the email and password", 'error');
        }
        else if (ValidationCheck.validateEmail(this.state.email)) {
            let requestData = {
                "email": this.state.email,
                "password": this.state.password,
                "feedback": this.state.feedback ? this.state.feedback : null
            }

            DeleteAccountService.deleteAccount(requestData).then(r => {
                if (r.ok) {
                    ShowAlert("Success", "You successfully requested for delete account", "success").then(r => {
                        window.location.href = `/deleteaccount`;
                    });
                }
            });
        }
        else {
            ShowAlert("PassportPay", this.state.email == "" ? "Please enter the email" : "Please enter the valid email address", 'error')
        }
    }
}

export default DeleteAccount;