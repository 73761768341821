import React, { useEffect, useState } from 'react';
import './TransactionSuccessRate.css';
import { TransactionSuccessRateProps } from './TransactionSuccessRateModel';
import { Link } from 'react-router-dom';
import { greenTheme } from '../../Themes/GreenTheme';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface TransactionSuccessRate extends TransactionSuccessRateProps {
  title: string;
  showLinkBT?: boolean;
  showAtIndex?: number;
  LinkBTTitle?: string;
  onReasonsClick?: (index: number) => void;
  dropdownData?: string[];
  onDropdownChange?: (selectedOption: string) => void; // Add the dropdown change callback prop
  showDPAtIndex?: number;
}

const TransactionSuccessRate: React.FC<TransactionSuccessRate> = ({
  transactionSuccessRate,
  title,
  showLinkBT,
  LinkBTTitle,
  showAtIndex,
  onReasonsClick,
  dropdownData,
  onDropdownChange,
  showDPAtIndex
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(dropdownData && dropdownData.length > 0 ? dropdownData[0] : '');

  // Automatically select the first value in dropdownData
  useEffect(() => {
    if (dropdownData && dropdownData.length > 0) {
      setSelectedOption(dropdownData[0]);
      if (onDropdownChange) {
        onDropdownChange(dropdownData[0]); // Trigger the callback with the first value
      }
    }
  }, [dropdownData]);

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    if (onDropdownChange) {
      onDropdownChange(selectedValue); // Trigger the callback with the selected value
    }
  };

  return (
    <div style={{}}>
       <h3 className='d-title' style={{ textAlign: 'center',paddingTop:'10px' }}>{title}</h3>
      <div className="" style={{}}>
        {transactionSuccessRate.map((transactionSuccessRate, index) => (
          <div key={index}   className="metric-cell" style={{ display: 'flex', flexDirection: 'column',  margin:'10px', height:'150px'}}>
            <label className="dashboard-widget-lable1" style={{ textAlign: 'start' }}>
              {transactionSuccessRate.title}
            </label>
            <label className="big-title-lbl" style={{ textAlign: 'center' }}>
              {transactionSuccessRate.value ?? '-'}
            </label>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              {showLinkBT && index === showAtIndex && onReasonsClick && (
                <Link
                  style={{ color: greenTheme.palette.secondary.main }}
                  to=""
                  onClick={() => onReasonsClick(index)}
                >
                  {LinkBTTitle}
                </Link>
              )}
              {showDPAtIndex !== undefined && index === showDPAtIndex && dropdownData && (
                <Select
                  value={selectedOption}
                  onChange={handleDropdownChange}
                  sx={{
                    height: '28px'
                  }}
                >
                  {dropdownData.map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransactionSuccessRate;