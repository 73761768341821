import BaseService from "./BaseService";

export default class TransactionService extends BaseService {
    public static getTransactionHistory(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'payment/transaction/all/history', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }

    public static initiatetransaction(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'payment/initiate/refund', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }

    public static updateFraud(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'payment/updatefraudstatus', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
    public static getCompliance(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'support/get/v2', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
    public static updateCompliance(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'support/update', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
}