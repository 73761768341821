import useRegix from './Config';

//helper to validate
class ValidationCheck {

  //email validation
  static validateEmail(email: string): boolean {
    // Regular expression for email validation
    const emailRegex = useRegix.Email
    return emailRegex.test(email);
  }

  //password length validation
  static validateMinLength(password: string, minLength: number): boolean {
    return password.length >= minLength;
  }

  //password validation
  static validatePasswordStrength(password: string): boolean {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,}$/;
    if (passwordRegex.test(password)) {
      return true;
    } else {
      return false;
    }
  }

  static validateFullName(name: string): boolean {
    // Regular expression for email validation
    const fullNameRegex = /^[A-Za-z0-9_,.&()\s/'áéíóúüñÑüÃÍÖöŠš]{3,}$/;
    return fullNameRegex.test(name);
  }

  static validateMobileNumber(number: string): boolean {
    const mobileNumberRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return mobileNumberRegex.test(number);
  }

  static validateZipCode(zip: string): boolean {
    const zipCodeRegex = /^\d{5}(?:-\d{4})?$/;
    const isValid = zipCodeRegex.test(zip);
    return isValid;
  }

  static validateCity(city: string): boolean {
    const cityRegex = /^[a-záéíóúüñÑÃÍÖŠšéâïö.'\s]{0,45}$/i;;
    const isValid = cityRegex.test(city);
    return isValid;
  }
  static validateState(state: string): boolean {
    const usStateRegex = /^[A-Z]{2}$/;
    const isValid = usStateRegex.test(state);
    return isValid;
  }
  static accountNumber(ac: string): boolean {
    const accountRegex = /^\d{9,12}$/;
    const isValid = accountRegex.test(ac);
    return isValid;
  }
  static accountBranchCode(bc: string): boolean {
    const fullNameRegex = /^[A-Za-z0-9_,.&()\s/'áéíóúüñÑüÃÍÖöŠš]*$/;
    return fullNameRegex.test(bc);
  }
  static payAmount(pa: string): boolean {
    const fullNameRegex = /^(\d+(\.\d{0,2})?)?$/;
    return fullNameRegex.test(pa);
  }
  static validateTopUpAmt(TU: string): boolean {
    if(Number(TU) > 0){
      return true;
    }else{
      return false;
    }
  }
  
}

export default ValidationCheck;