import React from 'react';
import { loadFromLocalStorage } from '../../Common/Validation/LocalStorage';
import DataGrid from '../../Component/DataGrid/DataGrid';
import './UserLogs.css';
import { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { IUserLogs, UserLogsProps, UserLogsState } from './Interface';
import UserLogsService from '../../Services/UserLogsService';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';

class UserLogs extends React.Component<UserLogsProps, UserLogsState> {
    private tableColumns: TableColumn<IUserLogs>[] = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            id: "name",

        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            id: "title",

        },
        {
            name: 'Log',
            selector: row => row.log,
            sortable: true,
            maxWidth: '400px',
            id: "log",
            cell: row => <div className='log-details' title={row.log} onMouseUp={() => {
                this.copySelectedText();
            }}>{row.log}</div>
        },
        {
            name: 'Date',
            selector: row => moment(row.created_utc).format('MM-DD-YYYY hh:mm A'),//hh:mm A on Do MMMM YYYY
            sortable: true,
            id: "date"
        }
    ]


    constructor(props_: UserLogsProps) {
        super(props_);

        this.state = {
            accessToken: "",
            userActionData: [],
            currentRowsPerPage: 10,
            currentPage: 1,
            totalRows: 100,
            searchText: "",
            showSnackbar: false,
            snackbarTransition: Fade,
            selectedText: ""
        }
    }

    async componentDidMount() {
        console.log('Enered');
        const accesstoken_ = await loadFromLocalStorage('accessToken');

        if (accesstoken_) {
            this.setState({
                accessToken: accesstoken_.toString()
            }, () => {
                this.getUserActions();
            })

        }
        else {
            localStorage.clear();
            window.location.href = '/'
        }
    }


    public render() {
        return (
            <div className='body-sub-contianer table-container'>
                {/* <h1>Transaction Logs</h1> */}
                <DataGrid
                    title='User Logs'
                    search='User Logs'
                    onSearch={(searchText_: string) => {
                        this.setState({
                            searchText: searchText_,
                            currentPage: 1
                        }, () => {
                            this.getUserActions();
                        })
                    }}
                    columns={this.tableColumns}
                    data={this.state.userActionData}
                    onChangePage={(page: number, totalRows: number) => {
                        console.log(page, totalRows);
                        this.setState({
                            currentPage: page
                        }, () => {
                            this.getUserActions();
                        })
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage_: number, currentPage_: number) => {
                        console.log(currentRowsPerPage_, currentPage_);
                        this.setState({
                            currentRowsPerPage: currentRowsPerPage_,
                            currentPage: currentPage_
                        }, () => {
                            this.getUserActions();
                        })
                    }}
                    pagination
                    paginationTotalRows={this.state.totalRows}
                />
                {this.state.showSnackbar && <Snackbar
                    open={true}
                    onClose={() => {
                        this.setState({
                            selectedText: "",
                            showSnackbar: false
                        })
                    }}
                    TransitionComponent={this.state.snackbarTransition}
                    message={"Copied to clipboard"}
                    key={'selectedText'}
                    autoHideDuration={1200}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                />}
            </div>
        )
    }

    private copySelectedText() {
        this.setState({
            showSnackbar: false
        }, () => {
            let selectedText_ = this.getSelected().toString();

            if (selectedText_) {
                this.setState({
                    selectedText: selectedText_,
                    showSnackbar: true
                })
            }
            console.log(selectedText_);
        })
        
    }

    private getSelected() {
        let t: any = '';
        if (window.getSelection) {
            t = window.getSelection();
        } else if (document.getSelection) {
            t = document.getSelection();
        }

        return t;
    }

    private getUserActions() {
        let pageIndex: number = (this.state.currentPage - 1);
        let pagination: any = {
            "pageNumber": (pageIndex * this.state.currentRowsPerPage),
            "recordPerPage": this.state.currentRowsPerPage
        }

        if (this.state.searchText) {
            pagination["searchText"] = this.state.searchText
        }

        UserLogsService.getUserAction(this.state.accessToken, pagination).then(tr => {
            if (tr.ok) {
                tr.json().then(resp => {
                    this.setState({
                        userActionData: resp.logsList,
                        totalRows: resp.pageCount

                    }, () => {
                        console.log(this.state.userActionData);
                    })
                });
            }
        });
    }
}

export default UserLogs;