import BaseService from "./BaseService";

export default class LoginServices extends BaseService {
    public static sendOTP(postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'request-email-otp', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                
            },
            body: JSON.stringify(postData)
        });
    }

    public static verifyOTP(postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'verify-email-otp', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                
            },
            body: JSON.stringify(postData)
        });
    }


}