import React from 'react';
import { loadFromLocalStorage } from '../../Common/Validation/LocalStorage';
import DataGrid from '../../Component/DataGrid/DataGrid';
import DataTable, { TableColumn } from 'react-data-table-component';
import { ICompliance, RequestType } from '../TransactionLogs/Interface';
import { Box, Button, TextField, Typography, buttonBaseClasses } from '@mui/material';
import ComplianceForm, { Status } from '../../Component/ComplianceForm';
import TransactionService from '../../Services/TransactionService';
import moment from 'moment';
import { ShowAlert } from '../../Component/ShowAlert';
// import { Link } from "react-router-dom";

interface ComplianceProps { }
interface ComplianceState {
    currentPage: number,
    accessToken: string,
    complianceData: ICompliance[],
    currentRowsPerPage: number,
    totalRows: number,
    searchText: string,
    adminCommandTF: string,

}

class Compliance extends React.Component<ComplianceProps, ComplianceState> {
    private tableColumns: TableColumn<ICompliance>[] = [
        {
            name: 'Request Id',
            selector: row => row.ticketNumber,
            sortable: false,
            id: "RequestId",

        },
        {
            name: 'Transaction Id',
            selector: row => row.transactionId,
            sortable: true,
            id: "TransactionId",
            // cell: row => <Link to={"/transactionlogs?id=" + row.transactionId} >{row.transactionId}</Link>
            cell: row => <a href={"/transactionlogs?id=" + row.transactionId} >{row.transactionId}</a>
        },
        {
            name: 'Request Type',
            selector: row => row.ticketType,
            sortable: true,
            id: "RequestType",

        },
        {
            name: 'Requester',
            selector: row => row.customer,
            sortable: true,
            id: "Requester",

        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: false,
            id: "Description",

        },
        {
            name: 'Status',
            selector: row => row.ticketStatus,
            sortable: true,
            id: "Status",

        },
        {
            name: 'Request Date',
            selector: row => moment(row.created_utc).format('MM-DD-YYYY hh:mm A'),
            sortable: true,
            id: "RequestDate",

        },
        {
            name: 'Completed Date',
            selector: row => row.resolved_utc ? moment(row.resolved_utc).format('MM-DD-YYYY hh:mm A') : "-",
            sortable: true,
            id: "CompletedDate",

        },

    ]

    adminComment = "";
    ticketStatus: Status | undefined;

    constructor(props_: ComplianceProps) {
        super(props_);

        this.state = {
            accessToken: "",
            complianceData: [],
            currentRowsPerPage: 10,
            currentPage: 1,
            totalRows: 100,
            searchText: "",
            adminCommandTF: ""
        }
    }

    async componentDidMount() {
        const accesstoken_ = await loadFromLocalStorage('accessToken');

        if (accesstoken_) {
            this.setState({
                accessToken: accesstoken_.toString()
            }, () => {
                this.getCompliance();
            })

        }
        else {
            localStorage.clear();
            window.location.href = '/'
        }
    }
    private getCompliance() {
        let pageIndex: number = (this.state.currentPage - 1);
        let pagination: any = {
            "pageNumber": (pageIndex * this.state.currentRowsPerPage),
            "recordPerPage": this.state.currentRowsPerPage
        }

        if (this.state.searchText) {
            pagination["searchText"] = this.state.searchText
        }

        TransactionService.getCompliance(this.state.accessToken, pagination).then(tr => {
            if (tr.ok) {
                tr.json().then(resp => {
                    this.setState({
                        complianceData: resp.complianceList,
                        totalRows: resp.pageCount
                    }, () => {

                    })
                });
            }
        });
    }


    handleCommandSubmit = (command: string) => {
        console.log('Command submitted:', command);
        // Add your logic to handle the command here
    }


    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ adminCommandTF: event.target.value });
    };

    handleFormSubmit = (formData: any) => {
        console.log('Form submitted:', formData);
        const statusArray = Object.values(Status);
        const indexOfStatus = statusArray.indexOf(formData.status);
        if (this.adminComment == formData.command && this.ticketStatus == formData.status) {
            ShowAlert("PassportPay", "No Changes to update", 'error')

        } else if (formData.command == '' || formData.command == null) {
            ShowAlert("PassportPay", "Please enter the admin comment", 'error')
        }
        else {
            console.log("formData.command == ''", formData.command);
            let pagination: any = {
                "ticketId": formData.ticketNumber,
                "ticketStatus": indexOfStatus + 1,
                "adminComment": formData.command.trim() ?? ""
            }
            TransactionService.updateCompliance(this.state.accessToken, pagination).then(tr => {
                if (tr.ok) {
                    this.getCompliance();
                    ShowAlert("PassportPay", "Submitted Successfully", 'success')
                    tr.json().then(resp => {
                    })
                }
            })
        }



        // Add your logic to handle the form data here
    };

    public render() {

        return (
            <div className='body-sub-contianer table-container'>
                {/* <h1>Transaction Logs</h1> */}
                <DataGrid
                    title='Compliance Logs'
                    search='Compliance Logs'
                    onSearch={(searchText_: string) => {
                        this.setState({
                            searchText: searchText_,
                            currentPage: 1
                        }, () => {
                            this.getCompliance();
                        })
                    }}
                    columns={this.tableColumns}
                    data={this.state.complianceData}
                    expandableRows
                    onChangePage={(page: number, totalRows: number) => {
                        console.log(page, totalRows);
                        this.setState({
                            currentPage: page
                        }, () => {
                            this.getCompliance();
                        })
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage_: number, currentPage_: number) => {
                        console.log(currentRowsPerPage_, currentPage_);
                        this.setState({
                            currentRowsPerPage: currentRowsPerPage_,
                            currentPage: currentPage_
                        }, () => {
                            this.getCompliance();
                        })
                    }}
                    pagination
                    paginationTotalRows={this.state.totalRows}
                    expandedComponent={(data_: any) => {
                        console.log("data", data_.data.compliance_details);
                        this.ticketStatus = data_.data.ticketStatus;
                        this.adminComment = data_.data.adminComment;
                        return (
                            <div className='details-container' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <ComplianceForm
                                    onSubmit={this.handleFormSubmit}
                                    initialData={{
                                        ticketNumber: data_.data.ticketNumber,
                                        subject: data_.data.ticketType,
                                        description: data_.data.description,
                                        command: this.adminComment,
                                        status: this.ticketStatus ?? Status.RequestRegistered,
                                        customerName: data_.data.customer,
                                        customerEmail: data_.data.customerEmail,
                                        customerMobile: data_.data.customerMobile
                                    }}
                                />
                            </div>
                        )
                    }
                    }
                />

            </div>
        )
    }

}

export default Compliance;