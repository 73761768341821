import React from 'react';
import './AddCard.css';
import AddCardService from '../../Services/AddCardService';
import { ShowAlert } from '../../Component/ShowAlert';
import { ShowBlocker } from '../../Component/LoaderComponent';
// import { creditCardType } from 'credit-card-type';
var creditCardType = require("credit-card-type");



interface AddCardProps { }
interface AddCardState {
    profile: any;
    accessToken: any;
    cvvLength: number;
    cardLength: number;
}

class AddCard extends React.Component<AddCardProps, AddCardState> {

    private formRef: any = null;

    constructor(props_: AddCardProps) {
        super(props_);

        this.state = {
            profile: {},
            accessToken: "",
            cvvLength: 3,
            cardLength: 19
        }

        this.formRef = React.createRef();
    }

    componentDidMount(): void {
        this.getUserDetails();
    }

    public render() {
        return (
            <form className="credit-card" id="userDetails" ref={this.formRef}>
                <div className="form-header">
                    <h4 className="title">Card detail</h4>
                </div>

                <div className="form-body">
                    <div className='hidden-items'>
                        <input type="text" id="addressLine1" name="addressLine1" value={this.state.profile.address_line1} /><br /><br />
                        <input type="text" id="addressLine2" name="addressLine2" value={this.state.profile.address_line2} /><br /><br />
                        <input type="text" id="state" name="state" value={this.state.profile.state} /><br /><br />
                        <input type="text" id="city" name="city" value={this.state.profile.city} /><br /><br />
                        <input type="text" id="zip" name="zip" value={this.state.profile.zip} /><br /><br />
                        <input type="text" id="avsFirstName" name="avsFirstName" value={this.state.profile.first_name} /><br /><br />
                        <input type="text" id="avsLastName" name="avsLastName" value={this.state.profile.last_name} /><br /><br />
                        <input type="text" id="avsAddressLine" name="avsAddressLine" value={this.state.profile.address_line1} /><br /><br />
                        <input type="text" id="avsZip" name="avsZip" value={this.state.profile.zip} /><br /><br />
                        <input type="text" id="avsEmail" name="avsEmail" value={this.state.profile.email} /><br /><br />
                        <input type="text" id="avsPhone" name="avsPhone" value={this.state.profile.mobile_number} /><br /><br />
                        <input type="text" id="avsState" name="avsState" value={this.state.profile.zip} /><br /><br />
                        <input type="text" id="avsCountry" name="avsCountry" value={this.state.profile.zip} /><br /><br />
                        <input type="text" id="avsCity" name="avsCity" value={this.state.profile.city} /><br /><br />
                        <input type="text" id="phone" name="phone" value={this.state.profile.mobile_number} /><br /><br />
                        <input type="text" id="email" name="email" value={this.state.profile.email} /><br /><br />
                        <input type="text" id="firstName" name="firstName" value={this.state.profile.first_name} /><br /><br />
                        <input type="text" id="lastName" name="lastName" value={this.state.profile.last_name} /><br /><br />
                        <input type="text" id="country" name="country" value={this.state.profile.city} /><br /><br />
                    </div>

                    <input type="tel" className="card-number" name='cardNumber' placeholder="Card Number" onInput={(event_) => {
                        this.formatCardNumber(event_);
                    }} onKeyDown={this.isNumber} maxLength={this.state.cardLength} />

                    <div className="date-field">
                        <div className="month">
                            {
                                this.renderMonth()
                            }
                        </div>
                        <div className="year">
                            <input type="tel" onInput={(event_) => {
                                this.formatNumber(event_);
                            }} className="card-number" name='expiryYear' placeholder="Year" maxLength={4} onKeyDown={this.isNumber} />
                        </div>
                    </div>

                    <div className="card-verification">
                        <div className="cvv-input">
                            <input type="password" placeholder="CVV" name='cvv' maxLength={this.state.cvvLength} onKeyDown={this.isNumber} />
                        </div>
                        <div className="cvv-details">
                            <p>3 or 4 digits usually found <br /> on the signature strip</p>
                        </div>
                    </div>

                    <button type="button" onClick={() => {
                        this.getToken();
                    }} className="proceed-btn">Proceed</button>

                </div>
            </form>
        )
    }

    renderMonth() {
        const months = Array.from({ length: 12 }, (_, index) => index + 1);
        return (
            <select name="expiryMonth">
                {
                    months.map(month => {
                        return (<option key={month} value={month}>{month.toString().padStart(2, '0')}</option>);
                    })
                }
            </select>
        );
    }

    private isNumber(evt: any) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode >= 96 && charCode <= 105)) {
            evt.preventDefault();
        }
    }

    private formatNumber(evt: any) {
        return evt.target.value.replace(/\D/g, '');
    }

    private formatCardNumber(evt: any) {
        let value = evt.target.value.replace(/\D/g, ''); // Remove all non-digit characters

        var cards = creditCardType(value.substring(0, 3));

        if (cards.length > 0) {
            if (cards.length <= 4) {
                this.setState({
                    cvvLength: cards[0].code.size,
                    cardLength: cards[0].lengths[0] + cards[0].gaps.length
                });
            }

            let cardGaps = cards[0].gaps;

            let outputCardNumber = "";
            value.split('').forEach((c: number, i: number) => {
                let space = (cardGaps.indexOf((i + 1)) !== -1 && ((value.length - 1) !== i)) ? " " : "";
                outputCardNumber += c + space;
            });

            evt.target.value = outputCardNumber;
        }
        else {
            this.setState({
                cvvLength: 3,
                cardLength: 19
            });
            value = value.match(/.{1,4}/g)?.join(' ') || value; // Add spaces every 4 digits
            evt.target.value = value;
        }

        // console.log(cards);
    }

    private getUserDetails() {
        const queryString = new URLSearchParams(window.location.search);
        console.log(process.env.REACT_APP_API_URL);
        let code = queryString.get("token");

        if (code) {
            AddCardService.getUserDetails(code).then(r => {
                if (r.ok) {
                    r.json().then(resp => {
                        this.setState({
                            profile: resp,
                            accessToken: code
                        });
                    });
                }
            });
        }
    }

    private async getToken() {
        ShowBlocker(true);
        console.log("this.formRef.current", this.formRef.current);
        const form = this.formRef.current;
        if (!form) return;


        let cardNumberInput = form.querySelector('input[name="cardNumber"]');
        if (cardNumberInput) {
            cardNumberInput.value = cardNumberInput.value.replace(/\s/g, ''); // Remove space 
        }

        const resp = await new PassportCheckout().getToken(form, {
            secureform: true,
            bindToken: false
        });
        // Incorporate space back  
        if (cardNumberInput) {
            cardNumberInput.value = cardNumberInput.value.match(/.{1,4}/g)?.join(' ') || '';
        }

        ShowBlocker(false);
        if (resp.status == 1) {
            let cardDetails = {
                "cardToken": `${resp.token}`
            };
            AddCardService.AddNewCard(this.state.accessToken, cardDetails).then(r => {
                if (r.ok) {
                    ShowAlert("Success", "You successfully added your card", "success").then(r => {
                        window.location.href = `/cardsuccess`;
                    });
                    // window.location.href = `/cardsuccess?token=${resp.token}`;
                    // window.location.href = `/cardsuccess`;
                    /*r.json().then(resp => {
                        this.setState({
                            profile: resp
                        });
                    });*/
                }
            });
        } else {
            ShowAlert("Error", resp.errorMessage ? resp.errorMessage : "Error", "error");
        }
    }
}

export default AddCard;