import React from 'react';
import { IconBaseProps } from 'react-icons';

interface IconBoxProps {
    icon: React.ReactNode; // Assuming `this.getIcon` returns a React node
    label: string;
    value: string | number;
    showAsFloat?: boolean;
}

// const IconBox: React.FC<IconBoxProps> = ({ icon, label, value }) => {
//     return (
//         <div className="dashboard-widget-spacing">
//             <div className="dashboard-widget">
//                 <div className="dashboard-widget-icon">{icon}</div>
//                 <div className="dashboard-widget-body">
//                     <div className="dashboard-widget-lable1" style={{}}>{label}</div>
//                     <div className="dashboard-widget-lable2">{value}</div>
//                 </div>
//             </div>
//         </div>
//     );
// };
const IconBox: React.FC<IconBoxProps> = ({ icon, label, value ,showAsFloat }) => {
    return (

        <div className="dashboard-widget" style={{ margin: '10px', height: '150px', flexDirection:'row', display:'flex',  backgroundColor:'#f2f2f2' }}>
            <div style={{}}>
                <div className="dashboard-widget-icon">{icon}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width:'80%' }}>
                <div className="dashboard-widget-lable1" style={{ marginLeft: '10px' }}>{label}</div>
                <div className="big-title-lbl ">{showAsFloat ? Number(value).toFixed(2) : value}</div>
                
            </div>
        </div>

    );
};


export default IconBox;