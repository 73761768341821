// TransactionFees.tsx

import React from 'react';
import styled from 'styled-components';
import './TransactionFees.css';

interface FeeData {
    title: string;
    amount: string;
    icon: string;
    subtitle?: string;
}

interface TransactionFeesProps {
    fees: FeeData[];

}

const TransactionFees: React.FC<TransactionFeesProps> = ({ fees }) => {
    return (
        <div className="styled-container">
            <div className="styled-cards-container">
                {fees.map((fee, index) => (
                    <div className="styled-card" key={index}>
                        <div className="styled-icon">{fee.icon}</div>
                        {
                            fee.subtitle ? <>
                                <label className="dashboard-widget-lable1" style={{color:'black'}}>{fee.title}</label>
                                <label className="dashboard-widget-lable1" style={{ marginTop: '-3px' }}>{fee.subtitle}</label></> : 
                                 <label className="dashboard-widget-lable1">{fee.title}</label>}

                        <div className="styled-amount">{`${fee.amount}`}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TransactionFees;