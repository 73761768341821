import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface IDataRow {
    [column: string]: string | number | Date | any;
}

export interface SearchProps<D> {
    onSearch: (searchText: string) => void;
    searchPlaceholder: string;
    defaultValue?: string;
}

export interface SearchState {
    searchText: string;
}


class Search<D extends IDataRow, C> extends React.Component<SearchProps<D>, SearchState> {

    constructor(props_: SearchProps<D>) {
        super(props_);

        this.state = {
            searchText: ""
        }
    }

    componentDidMount(): void {
        this.setState({
            searchText: this.props.defaultValue ? this.props.defaultValue : ""
        })
        console.log(this.props.defaultValue + "Component")
    }

    public render() {
        return (
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                onSubmit={(event) => {
                    event.preventDefault();
                    if (this.props.onSearch) {
                        this.props.onSearch(this.state.searchText);
                    }
                }}
            >
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => {
                    if (this.props.onSearch) {
                        this.props.onSearch(this.state.searchText);
                    }
                }}>
                    <SearchIcon />
                </IconButton>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    // defaultValue={this.props.defaultValue ? this.props.defaultValue : ""}
                    placeholder={`Search ${this.props.searchPlaceholder}...`}
                    inputProps={{ 'aria-label': this.props.searchPlaceholder }}
                    onChange={(event: any) => {
                        this.setState({
                            searchText: event.target.value
                        }, () => {
                            if (this.state.searchText == "" && this.props.onSearch) {
                                this.props.onSearch(this.state.searchText);
                            }
                        })
                        /*this.setState({
                            searchText: event.target.value
                        }, () => {
                            if (this.props.onSearch) {
                                this.props.onSearch(this.state.searchText);
                            }
                        })*/
                    }}
                    value={this.state.searchText}
                />
                {
                    this.state.searchText.length > 0 && <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => {
                        this.setState({
                            searchText: ""
                        }, () => {
                            if (this.props.onSearch) {
                                this.props.onSearch(this.state.searchText);
                            }
                        })
                    }}>
                        <ClearIcon />
                    </IconButton>
                }

            </Paper>
        )
    }
}

export default Search;