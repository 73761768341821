// common place to add/modify regix stuff 
const Regix = {
    Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    lengthVal: /^(?=.{5,10}$)/,
    oneUpperCase: /(?=.*[A-Z])/,
    oneLowerCase: /(?=.*[a-z])/,
    oneNumberCase: /(?=.*[0-9])/,
    oneSpecialCase: /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?\/\\])/,
    Password:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,}$/


};

export default Regix;