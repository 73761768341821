import React from 'react';
import Button from '@mui/material/Button';

interface BigButtonProps {
    onClick: () => void;
    buttonText: string;
    disabled: boolean;
    isCancel?: boolean;
    width?: string;
}

const BigButton: React.FC<BigButtonProps> = ({ onClick, buttonText, disabled, width, isCancel }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '10px 0px' }}>
            <Button
                className="button-text"
                sx={{
                    fontSize: '14px',
                    textTransform: 'none', // Prevent uppercase transformation
                    borderRadius: 30, // Set border radius
                    height: '46px', // Set height
                    color: isCancel ? '#7b7b7b' : 'white', // Set text color
                    backgroundColor: isCancel ? '#f1f1f1' : '#67d01c', // Set background color
                    '&:hover': {
                        backgroundColor: isCancel ? '#f1f1f1' : '#67d01c', // Set background color on hover
                    },
                    width: width ? width : 300,
                }}
                type='button'
                onClick={onClick}
                disabled={disabled}
            >
                {buttonText}
            </Button>
        </div>
    );
};

export default BigButton;