import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import TextField from '@mui/material/TextField';
import { amountFormat } from '../../../Component/helper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface RefundDialogPopopProps {
    open: boolean;
    onClose?: (state: boolean, amount: number, action: string) => void;
    paidAmount: number;
}
interface RefundDialogPopopState {
    amount: string;
    selectedAmount: string;
    inputType: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class RefundDialogPopop extends React.Component<RefundDialogPopopProps, RefundDialogPopopState> {
    constructor(props_: RefundDialogPopopProps) {
        super(props_);

        this.state = {
            amount: '',
            selectedAmount: 'paidAmount',
            inputType: 'text'
        }
    }

    public render() {
        return (
            <Dialog
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    this.handleClose('cancel')
                }}
                fullWidth={true}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Refund"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to refund the amount?
                    </DialogContentText>
                    <TextField
                        variant="outlined"
                        color="primary" // Use the primary color
                        value={amountFormat(parseFloat(this.props.paidAmount.toString().replace(/,/g, '')), 'en-US', 'USD').replace("$", "")}
                        disabled={this.state.selectedAmount == "customAmount"}
                        fullWidth
                        style={{marginTop: 10}}
                    />
                    {/* <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={this.state.selectedAmount}
                        name="radio-buttons-group"
                        onChange={(event) => {
                            this.setState({
                                selectedAmount: event.target.value
                            })
                        }}
                    >
                        <>
                            <FormControlLabel value="paidAmount" control={<Radio />} label="Paid Amount" />
                            <TextField
                                variant="outlined"
                                color="primary" // Use the primary color
                                value={amountFormat(parseFloat(this.props.paidAmount.toString().replace(/,/g, '')), 'en-US', 'USD').replace("$", "")}
                                disabled={this.state.selectedAmount == "customAmount"}
                            />

                        </>
                        <>
                            <FormControlLabel value="customAmount" control={<Radio />} label="Custom Amount" />
                            <TextField
                                placeholder='Refund custom amount *'
                                variant="outlined"
                                color="primary" // Use the primary color
                                value={this.state.amount}
                                onChange={(text) => {
                                    this.setState({ amount: text.target.value });
                                }}
                                onBlur={() => {
                                    let n_ = this.state.amount ? this.state.amount : '0';
                                    let udatedAmount = amountFormat(parseFloat(n_.replace(/,/g, '')), 'en-US', 'USD').replace("$", "");
                                    this.setState({ amount: udatedAmount, inputType: 'text' });
                                }}
                                onFocus={() => {
                                    let n_ = this.state.amount.replace(/,/g, '');
                                    this.setState({ amount: n_, inputType: 'number' });
                                }}
                                disabled={this.state.selectedAmount == "paidAmount"}
                                type={this.state.inputType}
                            />
                        </>
                    </RadioGroup> */}

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.handleClose('cancel');
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        this.handleClose('ok');
                    }} color='secondary' variant="contained">Ok</Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleClose = (action: string) => {
        // this.setState({
        //     open: false
        // })
        let amount_ = this.state.selectedAmount == "paidAmount" ? this.props.paidAmount : Number(this.state.amount.replace(/,/g, ''));

        if (this.state.selectedAmount)

            if (this.props.onClose) {
                this.props.onClose(false, amount_, action);
            }
    };
}

export default RefundDialogPopop;