import React, { ChangeEvent } from 'react';
import banner from '../../Images/banner.png';
import './Login.css';
import { Button, TextField, Typography, buttonBaseClasses } from '@mui/material';
import Box from '@mui/material/Box';
import ValidationCheck from '../../Common/Validation/ValidationChecks';
import BigButton from '../../Component/BigButton';
import { ShowAlert } from '../../Component/ShowAlert';
import LoginServices from '../../Services/LoginServices';
import { loadFromLocalStorage, saveToLocalStorage } from '../../Common/Validation/LocalStorage';
import AddCardService from '../../Services/AddCardService';
import WarningIcon from '@mui/icons-material/Warning';

interface LoginProps { }
interface LoginState {
    email: string;
    otp: string;
    showOTPView: boolean;
    isEmailfocussed: boolean;
    isOtpFocussed: boolean;

}


class Login extends React.Component<LoginProps, LoginState> {

    constructor(props: LoginProps) {
        super(props);
        this.state = {
            email: '',
            otp: '',
            showOTPView: false,
            isEmailfocussed: false,
            isOtpFocussed: false
        }
    }

    async componentDidMount() {
        const accesstoken = await loadFromLocalStorage('accessToken');

        if (accesstoken) {
            this.getUserDetails(accesstoken.toString());
        }
    }




    handleResend = () => {

    };

    handleChangeEmail = () => {
        // Logic to handle email change
    }
    private isNumber(evt: any) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode >= 96 && charCode <= 105)) {
            evt.preventDefault();
        }
    }
    private formatCardNumber(evt: any) {
        let value = evt.target.value.replace(/\D/g, ''); // Remove all non-digit characters
        value = value.match(/.{1,4}/g)?.join(' ') || value; // Add spaces every 4 digits
        evt.target.value = value;
        console.log("value", value);
    }

    public render() {
        return (
            <div className='login-parent-container'>
                <form className="login-container" id="userDetails" onSubmit={(event) => { event.preventDefault(); }}>
                    <div className="imgcontainer">
                        <img src={banner} alt="Avatar" className="avatar" />
                    </div>
                    <div className='login-title'>Sign in</div>
                    <div className='login-sub-header'>Sign in to access your account</div>
                    <div className="login-form-body">
                        <div className='login-email-container'>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 30px 10px 30px' }}>
                                {
                                    this.state.showOTPView ? <>
                                        <p style={{ textAlign: 'left', margin: '5px 0px' }}>
                                            Enter your OTP
                                        </p>
                                        <TextField
                                            placeholder='Your OTP'
                                            variant="outlined"
                                            color="primary" // Use the primary color
                                            value={this.state.otp}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                const numericValue = value.replace(/[^0-9]/g, '');

                                                this.setState({
                                                    isOtpFocussed: true
                                                }, () => {
                                                    if (numericValue.length <= 6) {
                                                        this.setState({ otp: numericValue });
                                                    }
                                                });
                                            }}
                                            style={{ padding: '5px 0px' }}
                                            inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }}
                                            className='web-input-field'
                                        />
                                        {
                                            (this.state.isOtpFocussed && this.state.otp == "") && <div className='error-msg'><WarningIcon /> Please enter the OTP</div>
                                        }

                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                            <p style={{ textAlign: 'center' }}>
                                                Didn't get the OTP?
                                            </p>
                                            <Button style={{ 'backgroundColor': 'white', color: '#67d01c', width: 'auto', height: 'auto', textTransform: 'capitalize' }} onClick={() => {
                                                if (ValidationCheck.validateEmail(this.state.email)) {
                                                    const postData = {
                                                        email: this.state.email
                                                    }
                                                    LoginServices.sendOTP(postData).then(response => {
                                                        if (response.ok) {
                                                            ShowAlert("PassportPay", "The OTP has been resent", 'success')
                                                        } else {
                                                            ShowAlert("PassportPay", "Something went wrong", 'error')
                                                        }
                                                    })
                                                } else {
                                                    ShowAlert("PassportPay", this.state.email == "" ? "Please enter the email" : "Please enter the valid email address", 'error')
                                                }
                                            }}>Resend</Button>
                                        </div>

                                        <BigButton
                                            disabled={this.state.otp == ""}
                                            onClick={() => {
                                                const postData = {
                                                    email: this.state.email,
                                                    otp: this.state.otp
                                                }

                                                LoginServices.verifyOTP(postData).then(response => {
                                                    if (response.ok) {
                                                        return response.json();
                                                    } else {
                                                        throw new Error("Network response was not ok.");
                                                    }
                                                })
                                                    .then(async data => {
                                                        const accessToken = data.accessToken;
                                                        console.log(accessToken);
                                                        saveToLocalStorage('accessToken', accessToken);
                                                        const LoadAccesstoken = await loadFromLocalStorage('accessToken');
                                                        console.log("LoadAccesstoken", LoadAccesstoken);
                                                        this.getUserDetails(accessToken.toString());

                                                        // window.location.href = '/dashboard';
                                                        // You can now use the accessToken as needed
                                                    })
                                                    .catch(error => {
                                                        console.error("There was a problem with the fetch operation:", error);
                                                    });

                                            }} buttonText="Continue" />

                                    </>
                                        :
                                        <>
                                            <p style={{ textAlign: 'left', margin: '5px 0px' }}>
                                                Enter your email address
                                            </p>
                                            {/* <Box
                                                component="form"
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <TextField
                                                    id="outlined-basic" label="Enter your email address *" variant="outlined"
                                                    // placeholder='Your email *'
                                                    // variant="outlined"
                                                    color="primary" // Use the primary color
                                                    value={this.state.email}
                                                    onChange={(text) => {
                                                        this.setState({ isEmailfocussed: true, email: text.target.value });
                                                    }}
                                                    className='web-input-field'
                                                />
                                            </Box> */}
                                            <TextField
                                                placeholder='Your email *'
                                                variant="outlined"
                                                color="primary" // Use the primary color
                                                value={this.state.email}
                                                onChange={(text) => {
                                                    this.setState({ isEmailfocussed: true, email: text.target.value });
                                                }}
                                                className='web-input-field'
                                            />

                                            {
                                                (this.state.isEmailfocussed && this.state.email != "" && !ValidationCheck.validateEmail(this.state.email)) && <div className="error-msg"><WarningIcon /> Please enter the valid email address</div>
                                            }

                                            {
                                                (this.state.isEmailfocussed && this.state.email == "") && <div className='error-msg'><WarningIcon /> Please enter the email</div>
                                            }


                                            <BigButton buttonText='Send OTP' disabled={!ValidationCheck.validateEmail(this.state.email)} onClick={() => {
                                                if (ValidationCheck.validateEmail(this.state.email)) {
                                                    const postData = {
                                                        email: this.state.email
                                                    }
                                                    LoginServices.sendOTP(postData).then(response => {
                                                        if (response.ok) {
                                                            this.setState({ showOTPView: true })
                                                        } else {
                                                            ShowAlert("PassportPay", "Something went wrong", 'error')
                                                        }
                                                    })
                                                }
                                                //  else {
                                                //     ShowAlert("PassportPay", this.state.email == "" ? "Enter email address" : "Invalid email address", 'error')
                                                // }
                                            }}></BigButton>



                                        </>
                                }
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        )
    }

    private getUserDetails(accessToken: string) {
        AddCardService.getUserDetails(accessToken).then(r => {
            if (r.ok) {
                r.json().then(resp => {
                    saveToLocalStorage('profile', resp);
                    window.location.href = '/dashboard'

                });
            }
            else {
                localStorage.clear();
            }
        });
    }
}

export default Login;