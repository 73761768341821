// import React from 'react';
// import './SupportTickets.css';
// import {SupportTicketsRateProps } from './SupportTicketsModal';



// const SupportTickets: React.FC<SupportTicketsRateProps> = ({ supportTicketsRate }) => {
//   return (
//     <div className="user-metrics-container">
//       <div style={{marginBottom:'20px'}} >
//         <label className='big-title-lbl '>Customer Support Tickets Metrics</label>
//       </div>
//       <div className="metrics-grid">
//         {supportTicketsRate.map((_supportTicketsRateProps, index) => (
//           <div key={index} className="metric-cell" style={{display:'flex', flexDirection:'column'}}>
//             <label className='dashboard-widget-lable1 ' style={{textAlign:'start'}}>{_supportTicketsRateProps.title}</label>
//             <label className='big-title-lbl 'style={{textAlign:'start'}}>{_supportTicketsRateProps.value}</label>
//             <p style={{textAlign:'start'}}>{_supportTicketsRateProps.subValue}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default SupportTickets;
import React from 'react';
import './SupportTickets.css';
import { SupportTicketsRateProps } from './SupportTicketsModal';

interface SupportTicketsProps extends SupportTicketsRateProps {
  columns: number; // Add a prop for the number of columns
  title: string;
}

const SupportTickets: React.FC<SupportTicketsProps> = ({ supportTicketsRate, columns, title }) => {
  return (
    <div className="user-metrics-container">
      {/* <div style={{ marginBottom: '20px' }}> */}
      {/* <label className="big-title-lbl">{title}</label> */}
      <h3 className='d-title' style={{ textAlign: 'center', paddingBottom: 10 }}>{title}</h3>
      {/* </div> */}
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center',  flex:1, height:'300px'}}>
        {
          supportTicketsRate.length ? <div
            className="metrics-grid"
            style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }} // Dynamically set columns
          >
            {supportTicketsRate.map((_supportTicketsRateProps, index) => (
              <div key={index} className="metric-cell" style={{ display: 'flex', flexDirection: 'column' }}>
                <label className="dashboard-widget-lable1" style={{ textAlign: 'center' }}>
                  {_supportTicketsRateProps.title}
                </label>
                <label className='styled-amount' style={{ textAlign: 'center' }}>
                  {_supportTicketsRateProps.value}
                </label>
                {/* <p style={{ textAlign: 'start' }}>{_supportTicketsRateProps.subValue}</p> */}
              </div>
            ))}
          </div> : <div style={{ height: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <label className='dashboard-widget-lable1' >{`No ${title}`}</label>
          </div>

        }
      </div>
    </div>
  );
};

export default SupportTickets;