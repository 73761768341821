import React from 'react';

interface PlaidRedirectProps { }
interface PlaidRedirectState {
    profile: any;
    accessToken: any;
    cvvLength: number;
    cardLength: number;
}

class PlaidRedirect extends React.Component<PlaidRedirectProps, PlaidRedirectState> {

    public render() {
        return (
            <div>Redirecting to the PassportPay app...</div>
        )
    }
    
}

export default PlaidRedirect;